import styled, { css } from 'styled-components';

interface IProps {
  dateValue: Date;
  sizeDisplay: '100' | '75' | '66' | '50' | '33' | '25';
}

export const Container = styled.div<IProps>`
  font-weight: 400;
  font-size: 12px;

  .rdt {
    input {
      height: 48px;
      border-radius: 8px;
      font-family: 'Mulish';
      border: 1px solid ${({ theme }) => theme.colors.border.primary};
      padding: 0 16px;

      font-weight: 500;
      font-size: 16px;

      color: ${({ theme }) => theme.colors.neutral[100]};
      outline: none;

      &::placeholder {
        color: ${({ theme }) => theme.colors.neutral[400]};
      }
    }

    .rdtPicker {
      margin-top: 5px;
      min-width: 229px;
      border-radius: 8px;
    }

    ${(props) =>
      props.sizeDisplay === '100'
        ? css`
            width: 100%;
          `
        : props.sizeDisplay === '75'
        ? css`
            width: calc(75% - 8px);
          `
        : props.sizeDisplay === '66'
        ? css`
            width: calc(66% - 8px);
          `
        : props.sizeDisplay === '50'
        ? css`
            width: calc(50% - 8px);
          `
        : props.sizeDisplay === '33'
        ? css`
            width: calc(33% - 8px);
          `
        : css`
            width: calc(25%- 11px);
          `}
  }

  @media only screen and (max-width: 480px) {
    .rdt input {
      width: 100%;
    }
  }
`;

export const Icon = styled.div`
  height: 0;
  position: relative;
  bottom: 23px;
  right: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  font-size: 22px;
  color: ${({ theme }) => theme.colors.neutral[400]};
  background-color: transparent;

  z-index: 1;
`;
