import { ChangeEvent } from 'react';
import { FiSearch } from 'react-icons/fi';

import { Container } from './styles';

interface IProps {
  handleInput(event: ChangeEvent<HTMLInputElement>): void;
  value: string;
  placeholder: string;
  sizeDisplay?: '100' | '75' | '66' | '50' | '33' | '25';
}

export const SearchBar = ({
  handleInput,
  placeholder,
  value,
  sizeDisplay = '100',
}: IProps) => (
  <Container sizeDisplay={sizeDisplay}>
    <input placeholder={placeholder} onChange={handleInput} value={value} />
    <span>
      <FiSearch size={22} />
    </span>
  </Container>
);
