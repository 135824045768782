/* eslint-disable import-helpers/order-imports */
import { useCallback, useState } from 'react';
import { HiCheck } from 'react-icons/hi';
import { TbExclamationMark } from 'react-icons/tb';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import { Chats, Paperclip, Check } from 'phosphor-react';
import moment from 'moment';
import { grantoApi } from 'src/services';

import { TypeStatus, TypesDocument, StepsTimeline } from 'src/utils/enum';
import { Policies, StepTimeline, TransferDocuments } from 'src/interfaces';

import 'react-vertical-timeline-component/style.min.css';
import theme from 'src/assets/styles/themes/default';

import { ErrorMessages, FormatDateToStepTimeline } from 'src/utils';
import { useAuth } from 'src/hooks/auth';
import { StepsTimelinePercentual } from 'src/utils/enum/StepsTimelinePercetual';
import { StyledButton } from '../StyledButton';
import {
  ButtonContent,
  Container,
  Content,
  DateContainer,
  ListDocumentsSent,
  PercentText,
  StepContainer,
  TextInformation,
} from './styles';
import { MultiDocumentsModal } from '../MultiDocumentsModal';
import { StatusModal } from '../StatusModal';
import { TooltipElement } from '../Tooltip';

interface IProps {
  policy: Policies;
  steps: StepTimeline[];
}

const Timeline = ({ policy, steps }: IProps) => {
  const { userLogged } = useAuth();
  const [modalDocument, setModalDocument] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState<TypeStatus>(TypeStatus.SUCCESS);
  const [typeDocuments, setTypeDocuments] = useState<Array<string>>();
  const [actualTimelineOpen, setActualTimelineOpen] = useState<
    number | undefined
  >();

  const [loading, setLoading] = useState(false);

  const last = steps[steps.length - 1];

  function StepsPercentual(lastStatus: string) {
    if (!lastStatus) {
      return StepsTimelinePercentual.DEFAULT;
    }

    if (lastStatus === StepsTimeline.RECEVEID) {
      return StepsTimelinePercentual.RECEVEID;
    }

    if (lastStatus === StepsTimeline.REVIEW_BLOCKED) {
      return StepsTimelinePercentual.REVIEW_BLOCKED;
    }

    if (lastStatus === StepsTimeline.RISK_ANALYSIS) {
      return StepsTimelinePercentual.RISK_ANALYSIS;
    }

    if (lastStatus === StepsTimeline.PROPOSAL_SENT) {
      return StepsTimelinePercentual.PROPOSAL_SENT;
    }
  }

  const handleTypeDocuments = (requiredDocuments?: string) => {
    if (requiredDocuments) {
      const types = requiredDocuments.split(',');
      setTypeDocuments(types.map((ty) => ty.trim()));
    }
  };

  const handleOpenWhatsApp = () => {
    if (process.env.REACT_APP_WHATSAPP_URL) {
      window.open(process.env.REACT_APP_WHATSAPP_URL, '_blank')?.focus();
    }
  };

  const handleOpenApproved = (doc?: string) => {
    if (doc) {
      window.open(doc, '_blank')?.focus();
    }
  };

  const handleSubmitDocument = useCallback(
    async (documents: Array<TransferDocuments>) => {
      try {
        setLoading(true);
        const formData = new FormData();

        documents.forEach((doc, index) => {
          formData.append(`Files[${index}].File`, doc.file);
          formData.append(`Files[${index}].Type`, doc.type);
        });

        formData.append('UserId', userLogged.id.toString());
        formData.append('PolicyId', policy.id.toString());
        formData.append('Type', TypesDocument.APOLICY);

        await grantoApi.makeHttpRequest({
          method: 'POST',
          url: `/policies/save-document/${actualTimelineOpen}`,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setModalDocument(false);
        setModal(true);
        setModalType(TypeStatus.SUCCESS);
        setModalMessage('Documento enviado com sucesso!');
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setModalDocument(false);
        setModal(true);
        setModalType(TypeStatus.ERROR);
        setModalMessage(ErrorMessages(error.response.data.errorCode));
      }
    },
    [actualTimelineOpen]
  );

  return (
    <>
      {modalDocument && (
        <MultiDocumentsModal
          setDocuments={handleSubmitDocument}
          setModalDocuments={(value) => {
            setModalDocument(value);
            setActualTimelineOpen(undefined);
          }}
          typeDocument={typeDocuments}
        />
      )}
      {modal && (
        <StatusModal
          successTitle="Sucesso"
          errorTitle="Erro"
          setModal={(value) => {
            setModal(value);
            window.location.reload();
          }}
          text={modalMessage}
          type={modalType}
        />
      )}
      <Container>
        <VerticalTimeline layout="1-column-left">
          {steps.map((step) => (
            <Content
              key={step.id}
              noHaveMore={step.id === last.id}
              exeption={
                step.id === last.id &&
                step.status === StepsTimeline.REVIEW_BLOCKED
              }
              haveList={step.status === StepsTimeline.REVIEW_BLOCKED}
            >
              <DateContainer>
                <p>{FormatDateToStepTimeline(step.createdAt)}</p>
              </DateContainer>
              <StepContainer
                exeption={
                  step.id === last.id &&
                  step.status === StepsTimeline.REVIEW_BLOCKED
                }
              >
                {step.id === last.id &&
                step.status === StepsTimeline.REVIEW_BLOCKED ? (
                  <VerticalTimelineElement
                    icon={<TbExclamationMark color={theme.colors.red} />}
                  >
                    <h3>Ops! {step.status}</h3>
                    <TextInformation>
                      <span>
                        Para seguirmos com a análise dos documentos, por
                        gentileza, envie o{' '}
                        <span className="term">{step.requiredDocuments}</span>
                      </span>
                    </TextInformation>
                    <ListDocumentsSent>
                      {step.documents?.map((doc) => (
                        <li key={Math.random()}>
                          {doc.typeDocument} -{' '}
                          <a download href={doc.path}>
                            {doc.filename}
                          </a>{' '}
                          ({moment(doc.createdAt).format('DD/MM/YYYY HH:mm')})
                        </li>
                      ))}
                    </ListDocumentsSent>
                    <ButtonContent>
                      <StyledButton
                        key="Falar com especialista"
                        text="Falar com especialista"
                        iconPosition="left"
                        icon={<Chats size={30} />}
                        onClick={handleOpenWhatsApp}
                        spinner={loading}
                      />
                      <TooltipElement
                        id="Falar com especialista"
                        content={step?.requiredDocuments || ''}
                      />
                      <StyledButton
                        text="Anexar documento"
                        iconPosition="left"
                        icon={<Paperclip size={30} />}
                        onClick={() => {
                          setActualTimelineOpen(step.id);
                          setModalDocument(true);
                          handleTypeDocuments(step.requiredDocuments);
                        }}
                        spinner={loading}
                      />
                      <TooltipElement
                        id="Anexar documento"
                        content={step?.requiredDocuments || ''}
                      />
                    </ButtonContent>
                  </VerticalTimelineElement>
                ) : (
                  <VerticalTimelineElement
                    icon={<HiCheck color={theme.colors.green.success} />}
                  >
                    <h3>{step.status}</h3>

                    {step.status === StepsTimeline.PROPOSAL_SENT && (
                      <ButtonContent>
                        <StyledButton
                          text="Aprove aqui"
                          iconPosition="left"
                          color={theme.colors.blue.primary}
                          icon={<Check size={30} />}
                          onClick={() => handleOpenApproved(step.observation)}
                          spinner={loading}
                        />
                      </ButtonContent>
                    )}

                    <ListDocumentsSent>
                      {step.documents?.map((doc) => (
                        <li key={Math.random()}>
                          {doc.typeDocument} -{' '}
                          <a download href={doc.path}>
                            {doc.filename}
                          </a>{' '}
                          ({moment(doc.createdAt).format('DD/MM/YYYY HH:mm')})
                        </li>
                      ))}
                    </ListDocumentsSent>
                  </VerticalTimelineElement>
                )}
                {step.id === last.id && (
                  <PercentText>{StepsPercentual(last.status)}%</PercentText>
                )}
              </StepContainer>
            </Content>
          ))}
        </VerticalTimeline>
      </Container>
    </>
  );
};

export { Timeline };
