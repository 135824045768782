/* eslint-disable import/no-extraneous-dependencies */
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

interface IProps {
  height?: number;
  count?: number;
}

export const SkeletonLine = ({ height = 30, count = 1 }: IProps) => (
  <Skeleton style={{marginBottom:8}} count={count} height={height} />
);
