/* eslint-disable react/no-array-index-key */

import { useNavigate } from 'react-router';

import { Notification, titles } from 'src/interfaces';
import { grantoApi } from 'src/services';
import { GroupedNotifications } from 'src/utils';

import {
  Body,
  Container,
  Divisor,
  DotAlert,
  NoticationContent,
  NotifcationBody,
  NotifcationHeader,
  NotificationTime,
  TimeLabel,
} from './styles';

interface IProps {
  opened: boolean;
  data: Notification[];
  setCloseDropdown: (value: boolean) => void;
  setReloadNotification: (value: boolean) => void;
}

interface GroupedNotifications {
  hoje: Array<Notification>;
  ontem: Array<Notification>;
  mais_antiga: Array<Notification>;
}

export const DropdownNotification = ({
  opened,
  data,
  setCloseDropdown,
  setReloadNotification,
}: IProps) => {
  const navigate = useNavigate();
  const notificationData = GroupedNotifications(data);

  const handleRedirect = (policyId: number, notifyId: string) => {
    handleReadNotification(notifyId);
    navigate(`my-policies/detail/${policyId}`);
    setCloseDropdown(false);
  };

  const handleReadNotification = async (id: string) => {
    try {
      await grantoApi.makeHttpRequest({
        method: 'GET',
        url: `/notificationcosmos/readnotification/${id}`,
      });

      setReloadNotification(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container>
      {opened ? (
        <>
          <h3>Notificações</h3>
          <Divisor />

          <Body>
            {data.length ? (
              notificationData.map((item: Notification) => (
                <>
                  {Object.entries(item).map(([key, value]) =>
                    value.length > 0 ? (
                      <section key={key}>
                        <TimeLabel>{titles[key]}</TimeLabel>
                        {value.map((notify: Notification) => (
                          <NoticationContent
                            key={notify.id}
                            onClick={() =>
                              handleRedirect(notify.idPolicy, notify.id)
                            }
                          >
                            <NotifcationHeader>
                              <span>{notify.title}</span>
                              <NotificationTime>
                                <span>({notify.elapsedTimeText})</span>
                                {!notify.toggle && <DotAlert />}
                              </NotificationTime>
                            </NotifcationHeader>
                            <NotifcationBody>
                              {notify.description}
                            </NotifcationBody>
                          </NoticationContent>
                        ))}
                      </section>
                    ) : null
                  )}
                </>
              ))
            ) : (
              <span>Nenhuma notificação</span>
            )}
          </Body>
        </>
      ) : null}
    </Container>
  );
};
