import styled, { css } from 'styled-components';

interface IProps {
  width: number | undefined;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #00000065;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div<IProps>`
  width: ${(props) => (props.width ? `${props.width}px` : '500px')};
  min-height: 300px;

  border-radius: 15px;
  padding: 20px;
  background: ${({ theme }) => theme.colors.white};

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  margin-top: 15px;

  .buttonClose {
    width: 100%;
    height: 48px;
    justify-content: center;
    margin-top: 5px;
  }
`;
