/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { connect, useSelector } from 'react-redux';

import { FloppyDisk } from 'phosphor-react';
import theme from 'src/assets/styles/themes/default';
import {
  AdminTitle,
  StatusModal,
  StyledButton,
  StyledInput,
  UserAvatar,
} from 'src/components';
import { SkeletonTemplateMyInfo } from 'src/components/Skeleton/SkeletonTemplateMyInfo';
import { setUser } from 'src/redux/slices/user';
import store, { RootState } from 'src/redux/store';
import { grantoApi } from 'src/services';
import {
  ErrorMessages,
  handleReduceUserName,
  handleToFormatBase64,
} from 'src/utils/';
import { TypeStatus } from 'src/utils/enum';

import {
  AvatarContent,
  ButtonContent,
  Container,
  InformationContainer,
  InputsContainer,
} from './styles';
import * as fO from './utils/formOptions';

export const MyInformations = () => {
  const {
    register,
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const { user } = useSelector((state: RootState) => state.user);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState<TypeStatus>(TypeStatus.SUCCESS);

  const [avatar, setAvatar] = useState<File>({} as File);

  const oldPassword = watch('oldPassword', '');
  const newPassword = watch('newPassword', '');

  const onSubmit = useCallback(
    async (data: any) => {
      try {
        setLoading(true);
        const formData = new FormData();

        if (avatar.name) {
          formData.append('DocumentFile.File', avatar);
        }

        formData.append('Nome', data.name);

        await grantoApi.makeHttpRequest({
          method: 'PUT',
          url: '/user',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        let resulUpdatePassword;

        if (data.oldPassword && data.newPassword) {
          const body = {
            OldPassword: data.oldPassword,
            NewPassword: data.newPassword,
          };
          resulUpdatePassword = await grantoApi.makeHttpRequest({
            method: 'PUT',
            url: '/user/update-password',
            data: body,
          });
        }

        setModal(true);
        setModalType(TypeStatus.SUCCESS);
        setModalMessage(
          resulUpdatePassword
            ? 'Senha redefinida com sucesso'
            : 'Informações do usuário alterada com sucesso'
        );

        setLoading(false);

        setValue('oldPassword', '');
        setValue('newPassword', '');

        store.dispatch(
          setUser({
            ...user,
            name: data.name,
            avatar: avatar.name
              ? await handleToFormatBase64(avatar).then((base64) => base64)
              : '',
            nameReduce: handleReduceUserName(data.name),
          })
        );
      } catch (error: any) {
        setLoading(false);
        setModal(true);
        setModalType(TypeStatus.ERROR);
        setModalMessage(ErrorMessages(error.response.status));
      }
    },
    [avatar]
  );

  const canDisableButton = () => !isValid;

  useEffect(() => {
    reset(user);
  }, [user]);

  return (
    <>
      {modal && (
        <StatusModal
          successTitle="Sucesso"
          errorTitle="Erro"
          setModal={setModal}
          text={modalMessage}
          type={modalType}
        />
      )}
      <Container>
        <AdminTitle title="Minhas informações" subtitle="" />

        {loading && <SkeletonTemplateMyInfo />}

        {!loading && (
          <InformationContainer>
            <AvatarContent>
              <UserAvatar
                srcImage={user.avatar}
                nameReduce={handleReduceUserName(user.name)}
                setAvatarChange={setAvatar}
              />
            </AvatarContent>
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputsContainer>
                <StyledInput
                  label="Nome"
                  placeholder="Digite seu nome"
                  heigth="64"
                  fontSize="20"
                  register={register}
                  registerProps={{ name: 'name' }}
                />
                <StyledInput
                  label="E-mail"
                  heigth="64"
                  fontSize="20"
                  disabled
                  register={register}
                  registerProps={{ name: 'email' }}
                />
              </InputsContainer>
              <InputsContainer>
                <StyledInput
                  type={visible ? 'text' : 'password'}
                  icon={
                    visible ? (
                      <FiEye
                        onClick={() => {
                          setVisible(!visible);
                        }}
                        style={{ cursor: 'pointer' }}
                        color={theme.colors.neutral[400]}
                      />
                    ) : (
                      <FiEyeOff
                        onClick={() => {
                          setVisible(!visible);
                        }}
                        style={{ cursor: 'pointer' }}
                        color={theme.colors.neutral[400]}
                      />
                    )
                  }
                  label="Senha atual"
                  placeholder="Digite sua senha"
                  sizeDisplay="100"
                  heigth="64"
                  fontSize="20"
                  register={register}
                  registerProps={{
                    name: 'oldPassword',
                    options: {
                      ...fO.changePasswordOptions,
                      required: {
                        value: !!newPassword,
                        message: 'Campo obrigatório!',
                      },
                    },
                  }}
                  error={!!errors.oldPassword}
                  errorMessage={(errors.oldPassword?.message as string) || ''}
                />
                <StyledInput
                  type={visible ? 'text' : 'password'}
                  icon={
                    visible ? (
                      <FiEye
                        onClick={() => {
                          setVisible(!visible);
                        }}
                        style={{ cursor: 'pointer' }}
                        color={theme.colors.neutral[400]}
                      />
                    ) : (
                      <FiEyeOff
                        onClick={() => {
                          setVisible(!visible);
                        }}
                        style={{ cursor: 'pointer' }}
                        color={theme.colors.neutral[400]}
                      />
                    )
                  }
                  label="Nova senha"
                  placeholder="Digite sua nova senha"
                  sizeDisplay="100"
                  heigth="64"
                  fontSize="20"
                  register={register}
                  registerProps={{
                    name: 'newPassword',
                    options: {
                      ...fO.changePasswordOptions,
                      required: {
                        value: !!oldPassword,
                        message: 'Campo obrigatório!',
                      },
                    },
                  }}
                  error={!!errors.newPassword}
                  errorMessage={(errors.newPassword?.message as string) || ''}
                />
              </InputsContainer>

              <ButtonContent>
                <StyledButton
                  type="submit"
                  className="buttonClose"
                  color={theme.colors.gradient.primary}
                  text="Salvar alterações"
                  iconPosition="left"
                  icon={<FloppyDisk size={30} />}
                  iconWhiteBackground
                  disabled={canDisableButton()}
                />
              </ButtonContent>
            </form>
          </InformationContainer>
        )}
      </Container>
    </>
  );
};

const mapStateToProps = (state: any) => ({ user: state.user });

export default connect(mapStateToProps)(MyInformations);
