import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { AppContext } from '../../hooks';
import { Admin } from '../admin';
import { Login } from '../Login';
import { Container } from './styles';

const queryClient = new QueryClient();

const App = () => (
  <AppContext>
    <Container>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/auth/recuperar-senha" element={<Login />} />
            <Route path="/auth/cadastrar-senha" element={<Login />} />
            <Route path="admin/*" element={<Admin />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Container>
  </AppContext>
);

export default App;
