import styled, { css } from 'styled-components';

interface IProps {
  sizeDisplay: '100' | '75' | '66' | '50' | '33' | '25';
}

export const Container = styled.div<IProps>`
  border: 1.5px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 8px;

  background-color: ${({ theme }) => theme.colors.white};

  display: flex;
  align-items: center;
  gap: 16px;

  padding: 0 26px;

  ${(props) =>
    props.sizeDisplay === '100'
      ? css`
          width: 100%;
        `
      : props.sizeDisplay === '75'
      ? css`
          width: calc(75% - 8px);
        `
      : props.sizeDisplay === '66'
      ? css`
          width: calc(66% - 8px);
        `
      : props.sizeDisplay === '50'
      ? css`
          width: calc(50% - 8px);
        `
      : props.sizeDisplay === '33'
      ? css`
          width: calc(33% - 8px);
        `
      : css`
          width: calc(25% - 11px);
        `}

  input {
    width: 100%;
    padding: 12px 0;
    font-weight: 500;
    font-size: 16px;
    background: none;
    border: none;
    outline: none;

    &::placeholder {
      color: ${({ theme }) => theme.colors.neutral[400]};
    }

    span {
      display: flex;
      align-items: center;

      svg {
        color: ${({ theme }) => theme.colors.neutral[400]};
      }
    }
  }

  @media only screen and (max-width: 480px) {
    padding: 0 12px;
  }
`;
