import styled from 'styled-components';

import hoverLogo from '../../assets/images/logos/hover-logo.png';
import miniLogo from '../../assets/images/logos/mini-logo.png';

export const BackContainer = styled.div`
  position: absolute;
  display: flex;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: 98;
`;

export const Container = styled.div`
  position: absolute;
  list-style: none;
  width: 250px;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.white};
  transition: width ease 0.5s;
  z-index: 99;

  .logoContainer {
    position: relative;
    height: 110px;
    text-align: center;
    padding: 40px 0;

    .miniLogo {
      position: absolute;
      top: 60%;
      left: 14%;
      height: 100%;
      width: 100%;
      background: url(${miniLogo}) no-repeat;
    }
  }

  .menu {
    padding: 0 20px;
    height: 64px;

    p {
      display: block;
    }
  }

  .logoContainer {
    .miniLogo {
      display: none;
    }

    .hoverLogo {
      position: absolute;
      left: 15%;

      height: 100%;
      width: 100%;
      background: url(${hoverLogo}) no-repeat;
    }
  }

  a {
    all: unset;
    cursor: pointer;
  }

  ul {
    margin-top: 45px;
  }
`;

export const Logout = styled.div`
  position: absolute;
  background: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  color: red;
  bottom: 30px;
`;
