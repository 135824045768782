import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  position: absolute;
  right: 0;

  min-width: 540px;
  max-height: 716px;
  padding: 32px;

  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 10px;

  box-shadow: ${({ theme }) => theme.shadow.default};
  z-index: 2;

  h3 {
    font-weight: 700;
    font-size: 24px;
  }

  @media only screen and (max-width: 480px) {
    min-width: 338px;
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 0.5px;

  background-color: ${({ theme }) => theme.colors.neutral[100]};
`;

export const TimeLabel = styled.h5`
  font-weight: 500;
  font-size: 12px;

  color: ${({ theme }) => theme.colors.neutral[300]};
`;

export const Body = styled.div`
  overflow-y: auto;
  padding: 0 12px 0 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  section {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  ::-webkit-scrollbar {
    width: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.neutral[600]};
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.blue.secondary};
    border-radius: 8px;
  }
`;

export const NoticationContent = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background.primary};

  padding: 16px;

  .no-data {
    background-color: ${({ theme }) => theme.colors.red};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.neutral[600]};
  }
`;

export const NotifcationHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: inherit;

  justify-content: space-between;

  span {
    font-weight: 700;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

export const NotificationTime = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  span {
    font-weight: 400;
    font-size: 10px;
    color: ${({ theme }) => theme.colors.neutral[300]};
  }
`;

export const DotAlert = styled.div`
  width: 8px;
  height: 8px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.red};
`;

export const NotifcationBody = styled.h4`
  max-width: 70%;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[100]};
`;
