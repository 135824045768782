import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';

import { AsideMenu, Header, AsideMobileMenu } from 'src/components';
import { RootState } from 'src/redux/store';

import { ProtectedRoute } from '../../Routes/Route';
import { MyInformations, MyPolicies, Home, PageNotFound } from './pages';
import { PolicyDetail } from './pages/MyPolicies/components';
import { AsideMenuDisplay, Container } from './styles';

export const Admin = () => {
  const { menu } = useSelector((state: RootState) => state.menu);
  return (
    <Container>
      {menu && <AsideMobileMenu />}

      <AsideMenuDisplay>
        <AsideMenu />
      </AsideMenuDisplay>

      <div className="contentContainer">
        <Header />
        <div className="dashboardContent">
          <div>
            <Routes>
              <Route element={<ProtectedRoute />}>
                <Route path="my-policies" element={<MyPolicies />} />
                <Route
                  path="my-policies/detail/:policyId"
                  element={<PolicyDetail />}
                />
                <Route path="my-informations" element={<MyInformations />} />
                <Route path="*" element={<PageNotFound />} />
                {/* <Route path="home" element={<Home />} /> */}
              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </Container>
  );
};
