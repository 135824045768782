import styled from 'styled-components';

interface IProps {
  disabled?: boolean | undefined;
}

export const TimelineContainer = styled.div`
  width: 100%;
  display: flex;

  padding: 16px 24px 50px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  box-shadow: ${({ theme }) => theme.shadow.default};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ActionButton = styled.a<IProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  min-width: 15vw;
  min-height: 132px;

  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral[500] : theme.colors.neutral[200]};

  border-radius: 10px;
  border: 1px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.neutral[500] : theme.colors.border.primary};
  background-color: ${({ theme }) => theme.colors.white};

  box-shadow: ${({ theme }) => theme.shadow.default};
  text-decoration: none;

  &:nth-child(1) {
    border: 2.5px solid ${({ theme }) => theme.colors.blue.secondary};
    color: ${({ theme }) => theme.colors.blue.secondary};

    p {
      font-weight: 700;
    }
  }

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? '' : theme.colors.blue.secondary};
    border: 2.5px solid
      ${({ theme, disabled }) => (disabled ? '' : theme.colors.blue.secondary)};

    p {
      font-weight: 700;
    }
  }

  p {
    font-weight: 500;
    font-size: 16px;
  }

  @media only screen and (max-width: 480px) {
    width: 165px;
    text-align: center;
  }
`;

export const ParcelDivisor = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;

  align-items: center;

  h2 {
    font-weight: 700;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.blue.primary};
  }
`;

export const Divisor = styled.div`
  width: 100%;
  height: 2px;

  background-color: ${({ theme }) => theme.colors.blue.primary};
`;

export const ParcelList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Parcel = styled.div<IProps>`
  width: 100%;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 24px;

  padding: 20px 16px;
  background-color: ${({ theme }) => theme.colors.white};

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};

  box-shadow: ${({ theme }) => theme.shadow.default};

  .parcel-button {
    height: 40px;
    padding: 10px 16px;

    font-size: 16px;
    font-weight: 500;

    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.white : theme.colors.neutral[100]};

    background-color: ${({ theme, disabled }) =>
      disabled ? theme.colors.neutral[500] : theme.colors.blue.quaternary};

    border-radius: 4px;
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  }

  @media only screen and (max-width: 480px) {
    .parcel-button {
      width: 100%;
    }
  }
`;

export const ParcelInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const ParcelStatus = styled.div`
  display: flex;
  gap: 80px;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 80px;

    p {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.neutral[100]};
    }

    .open {
      color: ${({ theme }) => theme.colors.yellow};
    }
    .paid {
      color: ${({ theme }) => theme.colors.green.success};
    }
    .overdue {
      color: ${({ theme }) => theme.colors.red};
    }
  }

  span,
  p {
    text-align: center;
    font-size: 16px;
  }

  span {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral[300]};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: space-between;

  @media only screen and (max-width: 1366px) {
    gap: 12px;
  }
`;
