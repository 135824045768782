import styled, { css } from 'styled-components';

interface IButtonProps {
  svgWhiteBackground: boolean;
  svgRadius: boolean;
  disabled: boolean;
  color?: string;
}

export const Button = styled.button<IButtonProps>`
  display: flex;
  gap: 14px;
  align-items: center;

  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.white};

  border-radius: 10px;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.neutral[500] : theme.colors.blue.primary};
  border: none;
  padding: 0 35px;

  transition: 0.2s ease;

  height: 64px;

  .arrow {
    display: flex;
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
`;
