/* eslint-disable react/no-array-index-key */
import { useState } from 'react';

// import { ICardPolicy, classname } from 'src/interfaces';
import { classname, ICardPolicy } from 'src/interfaces';
import { PolicyStatus } from 'src/utils/enum';

import { Container, Status } from './styles';

interface IProps {
  policy: ICardPolicy[];
  policyStatus: string;
  policyContract: string | undefined;
}

export const CardPolicy = ({
  policy,
  policyStatus,
  policyContract,
}: IProps) => (
  <Container policyStatus={policyStatus}>
    {policyStatus === PolicyStatus.INPROGRESS && (
      <>
        {policy.map((data, index) => (
          <div key={index}>
            <h4>{data.head}</h4>
            <p>{data.value}</p>
          </div>
        ))}
        <Status>
          <p className={classname[policyStatus]}>{policyStatus}</p>
        </Status>
      </>
    )}

    {(policyStatus === PolicyStatus.INFORCE ||
      policyStatus === PolicyStatus.DEFEATED) && (
      <>
        <div style={{ width: '16vw' }}>
          <h4>Contrato</h4>
          <p>{policyContract}</p>
        </div>

        <div>
          <div className="card-grid">
            {policy.map((data, index) => (
              <div key={index}>
                <h4>{data.head}</h4>
                <p>{data.value}</p>
              </div>
            ))}
          </div>
        </div>
        <Status style={{ justifyContent: 'start' }}>
          <p className={classname[policyStatus]}>{policyStatus}</p>
        </Status>
      </>
    )}
  </Container>
);
