import { Map } from 'src/interfaces';

export interface IParcel {
  id: number;
  policyId: number;
  vigencyDate: string;
  statusProgress: number;
  status: string;
  userId: number;
}

export const classNameParcel: Map = {
  'Em aberto': 'open',
  Pago: 'paid',
  Vencido: 'overdue',
};
