import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 14px;
  align-items: center;

  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.neutral[300]};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  button {
    display: flex;
    border: none;
    background: none;

    &:disabled {
      cursor: not-allowed;
    }
  }
`;
