import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import {
  StyledSelect,
  SearchBar,
  AdminTitle,
  StyledDateInput,
  DataTable,
} from 'src/components';
import { SkeletonLine } from 'src/components/Skeleton/SkeletonLine';
import { useAuth } from 'src/hooks/auth';
import { classname, Policies } from 'src/interfaces';
import { grantoApi } from 'src/services';
import {
  Debounce,
  CurrencyNormalizer,
  FormatDateTolist,
  FormatedToSelectArray,
  FormatDateToFilter,
} from 'src/utils';
import { ProfileCode } from 'src/utils/enum';
import { ScreenSizeValues } from 'src/utils/enum/ScreenSizeValues';
import useScreenSize from 'src/utils/ScreenSize';

import { Container, Row } from './styles';

type SelectValue = {
  value: string;
  label: string;
};

export const MyPolicies = () => {
  const { userLogged } = useAuth();
  const navigate = useNavigate();

  const isAdmin = userLogged.profile === ProfileCode.ADMIN;

  const { watch, control } = useForm({
    mode: 'onBlur',
  });

  const [loading, setLoading] = useState(false);
  const [actualPage, setActualPage] = useState(1);
  const [perPage, setperPage] = useState(10);
  const [totalResults, setTotalResults] = useState(0);

  const [policies, setPolicies] = useState<Policies[]>();

  const [text, setText] = useState<string>('');
  const [contractorFilter, setContractorFilter] = useState<SelectValue[]>([]);
  const [statusFilter, setStatusFilter] = useState<SelectValue[]>([]);

  const status = watch('status', '');
  const contractor = watch('contractor', '');
  const dateInit = watch('dateInit', '');
  const dateEnd = watch('dateEnd', '');

  const tableHead = [
    'CONTRATO',
    'CONTRATADA',
    'VALOR DO SEGURO',
    'IMPORTÂNCIA SEGURADA',
    'DATA DE EMISSÃO',
    'STATUS',
  ];

  const screenSize = useScreenSize();

  const handleInputValue = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;

    setText(value);
    setActualPage(1);
  }, []);

  const handleFetchData = useCallback(
    Debounce(
      async (
        textParams: string,
        contractParams: string,
        statusParams: number,
        dateInitialParams: string,
        dateEndParams: string
      ) => {
        try {
          setLoading(true);

          const response = await grantoApi.makeHttpRequest({
            method: 'GET',
            url: isAdmin ? '/policies' : '/policies/all',
            params: {
              Page: actualPage,
              PerPage: perPage,
              FilterString: textParams,
              Contractor: contractParams,
              Status: statusParams,
              DateInitial: dateInitialParams
                ? FormatDateToFilter(dateInitialParams)
                : '',
              DateEnd: dateEndParams ? FormatDateToFilter(dateEndParams) : '',
            },
          });
          const { total, data } = response.data;
          setTotalResults(total);
          setPolicies(data);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error(error);
        }
      }
    ),
    [policies, status, statusFilter, totalResults, actualPage, perPage]
  );

  const handleInputFilters = useCallback(
    Debounce(async () => {
      try {
        setLoading(true);

        const result = await grantoApi.makeHttpRequest({
          method: 'GET',
          url: '/policies/list-status',
        });
        result.data.shift();
        setStatusFilter(FormatedToSelectArray(result.data));

        if (isAdmin) {
          const res = await grantoApi.makeHttpRequest({
            method: 'GET',
            url: '/policies/list-contractors',
          });
          setContractorFilter(FormatedToSelectArray(res.data));
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    }),
    [statusFilter]
  );

  useEffect(() => {
    handleFetchData(text, contractor, status, dateInit, dateEnd);
  }, [text, contractor, status, dateInit, dateEnd, actualPage]);

  useEffect(() => {
    handleInputFilters();
  }, []);

  return (
    <Container>
      <AdminTitle title="Controle total das suas apólices" subtitle="" />
      <div className="headerBarContainer">
        <SearchBar
          placeholder="Buscar apólice"
          handleInput={handleInputValue}
          value={text}
          sizeDisplay={
            screenSize.width <= ScreenSizeValues.MOBILE ? '100' : '75'
          }
        />
        {userLogged.profile === ProfileCode.ADMIN && (
          <Controller
            name="contractor"
            control={control}
            render={({ field: { onChange } }) => (
              <StyledSelect
                options={contractorFilter}
                placeholder="Contratada"
                setValue={(val) => (val ? onChange(val.label) : onChange(val))}
                sizeDisplay={
                  screenSize.width <= ScreenSizeValues.MOBILE ? '100' : '75'
                }
              />
            )}
          />
        )}
        <Controller
          name="status"
          control={control}
          render={({ field: { onChange } }) => (
            <StyledSelect
              options={statusFilter}
              placeholder="Status"
              setValue={(val) => (val ? onChange(val.value) : onChange(val))}
            />
          )}
        />

        <Controller
          name="dateInit"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledDateInput
              placeholder="Data inicial"
              onChange={(val: Date) => onChange(val)}
              value={value}
              sizeDisplay={
                screenSize.width <= ScreenSizeValues.MOBILE ? '100' : '50'
              }
            />
          )}
        />

        <Controller
          name="dateEnd"
          control={control}
          render={({ field: { onChange, value } }) => (
            <StyledDateInput
              placeholder="Data final"
              onChange={(val: Date) => onChange(val)}
              value={value}
              sizeDisplay={
                screenSize.width <= ScreenSizeValues.MOBILE ? '100' : '50'
              }
            />
          )}
        />
      </div>

      {loading && <SkeletonLine height={40} count={10} />}

      {!loading && (
        <DataTable
          heads={tableHead}
          hasPagination
          setPage={setActualPage}
          page={actualPage}
          setItemsPerPage={setperPage}
          totalItems={totalResults}
          itemsPerPage={perPage}
        >
          <tbody>
            {policies?.map((policy) => (
              <Row
                key={policy.id}
                onClick={() => navigate(`detail/${policy.id}`)}
              >
                <td
                  className="contract"
                  style={{ fontWeight: 700, textDecoration: 'underline' }}
                >
                  {policy.contractNumber?.toLocaleUpperCase()}
                </td>
                <td>{policy.contractor}</td>
                <td>
                  {policy.insuranceValue
                    ? CurrencyNormalizer(
                        Number(policy.insuranceValue).toFixed(2)
                      )
                    : ''}
                </td>
                <td>
                  {policy.insuranceAmount
                    ? CurrencyNormalizer(
                        Number(policy.insuranceAmount).toFixed(2)
                      )
                    : ''}
                </td>
                <td>
                  {policy.issuanceDate
                    ? FormatDateTolist(policy.issuanceDate)
                    : ''}{' '}
                </td>
                <td
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    minWidth: '120px',
                    minHeight: '48px',
                    justifyContent: 'center',
                  }}
                >
                  <p className={classname[policy.status]}>{policy.status}</p>
                </td>
              </Row>
            ))}
          </tbody>
        </DataTable>
      )}
    </Container>
  );
};
