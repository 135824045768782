export const GroupedNotifications = (data: any[]): any => {
  const notificationsData = [];

  const groupedNotifications = Object.entries(data).reduce(
    (result: any, [key, value]) => ({
      ...result,
      [value.elapsedDaysText]: [
        ...(result[value.elapsedDaysText] || []),
        { ...value, name: key },
      ],
    }),
    {}
  );

  notificationsData.push(groupedNotifications);

  return notificationsData;
};
