export default {
  colors: {
    background: {
      primary: '#FCFCFC',
      secondary: '#FDFDFD',
    },

    blue: {
      primary: '#4510A3',
      secondary: '#7D58BF',
      tertiary: '#C8B8E4',
      quaternary: '#EDE8F6',
    },

    green: {
      primary: '#0CD2AB',
      success: '#059669',
    },

    neutral: {
      100: '#323232',
      200: '#474747',
      300: '#707070',
      400: '#999999',
      500: '#C2C2C2',
      600: '#EBEBEB',
    },

    gradient: {
      primary: 'linear-gradient(180deg, #6735BE 0%, #4803C2 100%)',
      secondary: 'linear-gradient(208.92deg, #BB96FB 15.86%, #A978FF 82.21%)',
      background: 'linear-gradient(118.69deg, #00C2AA 9.83%, #4B1BA8 86.31%)',
    },

    border: {
      primary: '#ECECEC',
    },

    white: '#FFFFFF',
    red: '#DC2626',
    yellow: '#EAB600',

    opacity: {
      red: '#DC262615',
      green: '#05966915',
      yellow: '#EAB60015',
    },
  },
  shadow: {
    default:
      '0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
};
