/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Select, {
  Options,
  components,
  NoticeProps,
  StylesConfig,
} from 'react-select';

import theme from 'src/assets/styles/themes/default';

import { Container, Icon, Label } from './styles';

interface IProps {
  placeholder: string;
  options: Array<any>;
  sizeDisplay?: '100' | '75' | '66' | '50' | '33' | '25';
  sizeHeight?: string;
  icon?: string | JSX.Element;
  label?: string;
  disabled?: boolean;
  enabledClear?: boolean;
  multiple?: boolean;
  style?: StylesConfig;
  value?:
    | { value: string; label: string }
    | Options<{ value: string; label: string }>;
  defaultValue?: { value: string; label: string };
  setValue: (value: any) => void;
}

const NoOptionsMessage = (props: any) => (
  <components.NoOptionsMessage {...props}>
    <span>Sem dados</span>
  </components.NoOptionsMessage>
);

const StyledSelect = ({
  options,
  icon,
  placeholder,
  sizeDisplay = '100',
  sizeHeight = '48',
  label,
  disabled = false,
  enabledClear = true,
  multiple,
  style,
  value,
  defaultValue,
  setValue,
}: IProps) => (
  <Container sizeDisplay={sizeDisplay}>
    <Label disabled={disabled}>{label}</Label>
    {icon && <Icon>{icon}</Icon>}
    <Select
      isClearable={enabledClear}
      isMulti={multiple}
      components={{ NoOptionsMessage }}
      defaultValue={defaultValue}
      options={options}
      placeholder={placeholder}
      isDisabled={disabled}
      value={value}
      isSearchable
      onChange={setValue}
      styles={
        style || {
          container: (base) => ({
            ...base,
            width: '100%',
            boxShadow: 'none',
          }),
          control: (base, state) => ({
            ...base,
            height: `${sizeHeight}px`,
            background: theme.colors.white,
            borderTop: `1px solid ${
              state.isDisabled
                ? theme.colors.neutral[600]
                : theme.colors.border.primary
            }`,
            borderRight: `1px solid ${
              state.isDisabled
                ? theme.colors.neutral[600]
                : theme.colors.border.primary
            }`,
            borderBottom: `1px solid ${
              state.isDisabled
                ? theme.colors.neutral[600]
                : theme.colors.border.primary
            }`,
            borderLeft: icon
              ? 'none'
              : `1px solid ${
                  state.isDisabled
                    ? theme.colors.neutral[600]
                    : theme.colors.border.primary
                }`,
            borderRadius: icon ? '15px 8px 8px 15px' : '8px',
            fontFamily: 'Mulish',
            fontWeight: 500,
            boxShadow: 'none',
            '&:hover': {
              borderTop: `1px solid ${theme.colors.border.primary}`,
              borderRight: `1px solid ${theme.colors.border.primary}`,
              borderBottom: `1px solid ${theme.colors.border.primary}`,
              borderLeft: icon
                ? 'none'
                : `1px solid ${theme.colors.border.primary}`,
            },
          }),
          option: (base, state) => ({
            ...base,
            display: 'flex',
            alignItems: 'center',
            fontFamily: 'Mulish',
            fontWeight: 300,
            fontSize: '14px',
            backgroundColor: state.isSelected
              ? theme.colors.neutral[600]
              : theme.colors.white,
            color: theme.colors.neutral[100],
            '&:hover': {
              backgroundColor: theme.colors.neutral[600],
            },
          }),
          menu: (base) => ({
            ...base,
            border: `1px solid ${theme.colors.border.primary}`,
            borderRadius: 4,
            margin: '3px 0',
            zIndex: 10,
          }),
          menuList: (base) => ({
            ...base,

            '::-webkit-scrollbar': {
              width: '9px',
              height: '10px',
            },
            '::-webkit-scrollbar-track': {
              background: theme.colors.neutral[300],
            },
            '::-webkit-scrollbar-thumb': {
              background: theme.colors.neutral[600],
            },
            '::-webkit-scrollbar-thumb:hover': {
              background: theme.colors.neutral[100],
            },
          }),
          dropdownIndicator: (base, state) => ({
            ...base,
            color:
              state.hasValue && !state.isDisabled
                ? theme.colors.neutral[600]
                : theme.colors.neutral[400],
          }),
          indicatorSeparator: (base, state) => ({
            ...base,
            visibility: state.hasValue ? 'visible' : 'hidden',
            height: '18px',
            marginBottom: '13px',
            marginTop: '14px',
          }),
          placeholder: (base) => ({
            ...base,
            fontFamily: 'Mulish',
            fontSize: '16px',
            fontWeight: 500,
            color: icon ? '' : theme.colors.neutral[400],
          }),
          valueContainer: (base) => ({
            ...base,
            paddingLeft: icon ? '50px' : '12px',
          }),
        }
      }
    />
  </Container>
);

export { StyledSelect };
