import styled from 'styled-components';

interface IFormContainer {
  minHeight: string;
}

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.gradient.background};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const BackContainer = styled.div`
  position: absolute;
  top: 88px;
  left: 4%;

  div {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  }

  span {
    font-size: 20px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 3px;
  }
`;

export const FormContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 480px) {
    position: absolute;
    padding: 16px;
    height: auto;
  }
`;

export const FormContainer = styled.div<IFormContainer>`
  width: 100%;
  height: 100%;
  max-width: 556px;
  min-height: ${(props) => `${props.minHeight}px`};

  display: flex;
  flex-direction: column;

  gap: 24px;

  border-radius: 10px;
  padding: 48px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06), 0px 4px 6px rgba(0, 0, 0, 0.1);

  h1 {
    font-size: 36px;
    color: ${({ theme }) => theme.colors.neutral[100]};
  }

  span {
    line-height: 32px;
    font-size: 24px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral[300]};
  }

  @media only screen and (min-width: 480px) and (max-width: 1366px) {
    min-height: 0;
    max-height: 520px;
    gap: 16px;
    padding: 40px;

    h1 {
      font-size: 32px;
    }

    span {
      font-size: 20px;
    }
  }
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media only screen and (max-width: 1366px) {
    gap: 8px;
  }
`;

export const InputsContainer = styled.div`
  padding: 32px 0 0;
  display: flex;
  flex-direction: column;
  gap: 48px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;

  .btn-submit {
    width: 100%;
    font-size: 20px;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.gradient.primary};

    @media only screen and (max-width: 1366px) {
      height: 56px;
      font-size: 18px;
    }
  }

  .forgot {
    margin-top: 20px;
  }

  .change {
    margin-top: 28px;
  }

  @media only screen and (max-width: 1366px) {
    gap: 16px;

    .forgot {
      margin-top: 15px;
    }

    .change {
      margin-top: 0;
    }
  }
`;

export const TextButton = styled.div`
  font-size: 20px;
  font-weight: 400;
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.neutral[400]};

  transition: color 0.2s;
  cursor: pointer;

  @media only screen and (max-width: 1366px) {
    font-size: 18px;
  }
`;
