/* eslint-disable import-helpers/order-imports */
/* eslint-disable @typescript-eslint/no-shadow */

import styled from 'styled-components';
import theme from 'src/assets/styles/themes/default';
import { TypeStatus } from 'src/utils/enum';

interface IProps {
  type: TypeStatus;
  width: number | undefined;
}

const sucess = [TypeStatus.SUCCESS];

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #00000065;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div<IProps>`
  min-height: 283px;
  width: ${(props) => (props.width ? `${props.width}px` : '380px')};
  border-radius: 10px;
  padding: 15px;
  background: ${({ theme }) => theme.colors.white};

  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  h3 {
    font-weight: 600;
    font-size: 24px;
    color: ${(props) =>
      sucess.includes(props.type)
        ? theme.colors.green.success
        : theme.colors.red};
    text-align: center;
  }

  p {
    max-width: 80%;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral[200]};
    text-align: center;
  }

  .buttonClose {
    width: 60%;
    height: 48px;
    justify-content: center;
    margin-top: 15px;
  }
`;

export const ButtonContent = styled.div`
  min-width: 470px;
  display: flex;
  gap: 16px;
  margin-top: 20px;
`;
