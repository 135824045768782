import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { StatusModal, StyledButton, StyledInput } from 'src/components';
import { useAuth } from 'src/hooks/auth';
import { ErrorMessages } from 'src/utils';
import { TypeStatus } from 'src/utils/enum';

import { ButtonsContainer, FormContainer, InputsContainer } from '../styles';
import * as fO from '../utils/formOptions';
import { FormState } from '../utils/TypeToForm';

interface IProps {
  setFormState: (value: SetStateAction<FormState>) => void;
  minHeighToForm: string;
  setModal: (value: boolean) => void;
  setModalMessage: (value: string) => void;
  setModalType: (value: SetStateAction<TypeStatus>) => void;
}

export const PasswordForgot = ({
  setFormState,
  minHeighToForm,
  setModal,
  setModalMessage,
  setModalType,
}: IProps) => {
  const { forgotPassword } = useAuth();

  const [loading, setLoading] = useState(false);
  const [localModal, setLocalModal] = useState(false);
  const [localModalMessage, setLocalModalMessage] = useState('');
  const [localModalType, setLocalModalType] = useState<TypeStatus>(
    TypeStatus.SUCCESS
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const canDisableButton = () => !isValid;

  const onSubmit = useCallback(async (data: any) => {
    try {
      setLoading(true);
      const result = await forgotPassword(data.email);

      if (result.status === TypeStatus.SUCCESS) {
        setModal(true);
        setModalType(TypeStatus.SUCCESS);
        setModalMessage(
          'Um e-mail de redefinição de senha foi enviado, verifique sua caixa de entrada!'
        );
        setFormState(FormState.SIGNIN);
      } else {
        setLocalModal(true);
        setLocalModalType(TypeStatus.ERROR);
        setLocalModalMessage(`Usuário ${ErrorMessages(result.code)}`);
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setLocalModal(true);
      setLocalModalType(TypeStatus.ERROR);
      setLocalModalMessage(`Usuário ${ErrorMessages(error.response.status)}`);
    }
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <>
      {localModal && (
        <StatusModal
          successTitle="Sucesso"
          errorTitle="Erro"
          setModal={setLocalModal}
          text={localModalMessage}
          type={localModalType}
          navigateTo="/"
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer minHeight={minHeighToForm}>
          <h1>Esqueceu sua senha?</h1>
          <span style={{ maxWidth: windowWidth <= 1366 ? '100' : '95%' }}>
            Digite seu endereço de e-mail cadastrado. Você receberá um link por
            e-mail para criar uma nova senha.
          </span>
          <InputsContainer>
            <StyledInput
              label="E-mail"
              placeholder="Digite seu e-mail"
              sizeDisplay="100"
              heigth={windowWidth <= 1366 ? '56' : '64'}
              fontSize={windowWidth <= 1366 ? '18' : '20'}
              labelSize={windowWidth <= 1366 ? '18' : '20'}
              register={register}
              registerProps={{ name: 'email', options: fO.emailOptions }}
              error={!!errors.email}
              errorMessage={(errors.email?.message as string) || ''}
            />
          </InputsContainer>
          <ButtonsContainer>
            <StyledButton
              className="btn-submit forgot"
              text="REDEFINIR SENHA"
              color="blue"
              type="submit"
              disabled={canDisableButton()}
              spinner={loading}
            />
          </ButtonsContainer>
        </FormContainer>
      </form>
    </>
  );
};
