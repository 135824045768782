/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { Bell, CaretLeft, CaretRight, List } from 'phosphor-react';
import theme from 'src/assets/styles/themes/default';
import { UserAvatar } from 'src/components';
import { Notification, policyPageTitle } from 'src/interfaces';
import { setShowMenu } from 'src/redux/slices/mobileMenu';
import store, { RootState } from 'src/redux/store';
import { grantoApi } from 'src/services';
import { Debounce, handleReduceUserName } from 'src/utils';
import { ProfileCode } from 'src/utils/enum';

import { DropdownNotification } from './components/DropdownNotification';
import {
  Container,
  Button,
  ButtonContent,
  BackContainer,
  Dot,
  UserAvatarContent,
  MenuIconContainer,
} from './styles';

export const Header = () => {
  const { user } = useSelector((state: RootState) => state.user);
  const { policy } = useSelector((state: RootState) => state.policy);
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const wrapperRef = React.createRef<HTMLDivElement>();

  const isAdmin = user.profile === ProfileCode.ADMIN;
  const isRouteMyInformation = pathname.includes('my-information');
  const isDetailPolicy = pathname.includes('my-policies/detail');

  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationCount, setNotificationCount] = useState<number | string>();
  const [reloadNotification, setReloadNotification] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleGetNotifications = useCallback(
    Debounce(async () => {
      try {
        const result = await grantoApi.makeHttpRequest({
          method: 'GET',
          url: isAdmin
            ? '/notificationcosmos/getAll'
            : `/notificationcosmos/getByUser/${user.id}`,
        });

        setNotifications(result.data);
        handleNotificationsCount(result.data);

        if (reloadNotification) {
          setReloadNotification(false);
        }
      } catch (error) {
        console.error(error);
      }
    }),
    [notifications, reloadNotification]
  );

  const handleNotificationsCount = (value: Notification[]) => {
    const quantity = value.filter((val) => !val.read);

    const result = quantity.length >= 10 ? '9+' : quantity.length;

    setNotificationCount(result);
  };

  const useOutsideClick = useCallback(
    (ref: React.RefObject<HTMLDivElement>) => {
      useEffect(() => {
        function handleClickOutside(event: any) {
          if (ref.current && !ref.current.contains(event.target)) {
            setOpen(!open);
          }
        }

        if (open) {
          document.addEventListener('click', handleClickOutside);
        }

        return () => {
          document.removeEventListener('click', handleClickOutside);
        };
      });
    },
    [wrapperRef]
  );

  useOutsideClick(wrapperRef);

  useEffect(() => {
    if (reloadNotification) {
      handleGetNotifications();
    }
  }, [reloadNotification]);

  useEffect(() => {
    handleGetNotifications();
  }, []);

  return (
    <Container
      breadcrumbs={isRouteMyInformation || isDetailPolicy}
      ref={wrapperRef}
    >
      <MenuIconContainer onClick={() => store.dispatch(setShowMenu(true))}>
        <div>
          <List size={32} weight="bold" color={theme.colors.neutral[100]} />
        </div>
      </MenuIconContainer>

      {isRouteMyInformation && (
        <BackContainer onClick={() => navigate('/admin/my-policies')}>
          <div className="mobileHide">
            <CaretLeft
              size={18}
              weight="bold"
              color={theme.colors.blue.primary}
            />
            <span>Voltar</span>
          </div>
        </BackContainer>
      )}

      {isDetailPolicy && (
        <BackContainer>
          <div className="mobileHide">
            <span
              onClick={() => navigate('/admin/my-policies')}
              className="my-policy"
            >
              Minhas apólices
            </span>
            <CaretRight
              size={18}
              weight="bold"
              color={theme.colors.neutral[500]}
            />
            <span>{policyPageTitle[policy?.status as string]}</span>
          </div>
        </BackContainer>
      )}

      <ButtonContent>
        <Button className="dropdown">
          <Bell
            style={{ cursor: 'pointer' }}
            size={32}
            weight="regular"
            onClick={handleOpen}
          />
          {notifications.length > 0 && (
            <Dot onClick={handleOpen}>
              <span>{notificationCount}</span>
            </Dot>
          )}
          {open && (
            <DropdownNotification
              setCloseDropdown={setOpen}
              setReloadNotification={setReloadNotification}
              data={notifications}
              opened={open}
            />
          )}
        </Button>
        <Button onClick={() => navigate('/admin/my-informations')}>
          <UserAvatarContent>
            <UserAvatar
              width="40"
              height="40"
              fontSize="20"
              showButton={false}
              srcImage={user.avatar}
              nameReduce={user.name ? handleReduceUserName(user.name) : ''}
            />
            <p className="userName">{user.name}</p>
          </UserAvatarContent>
        </Button>
      </ButtonContent>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  user: state.user,
  policy: state.policy,
});

export default connect(mapStateToProps)(Header);
