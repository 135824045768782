import styled from 'styled-components';

interface IProps {
  height?: string;
  width?: string;
  fontSize?: string;
  showButton?: boolean;
}

export const AvatarContent = styled.div<IProps>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '120px')};
  height: ${({ height }) => (height ? `${height}px` : '120px')};

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const DefaultAvatar = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '120px')};
  height: ${({ height }) => (height ? `${height}px` : '120px')};

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.blue.primary};

  span {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '64px')};
    color: ${({ theme }) => theme.colors.white};
  }
`;

export const ButtonContent = styled.div<IProps>`
  display: ${({ showButton }) => (!showButton ? 'none' : '')} !important;
  position: absolute;
  bottom: 0;
  right: 0;

  .btn-upload-avatar {
    width: 40px;
    height: 40px;

    background-color: ${({ theme }) => theme.colors.green.primary};
    border-radius: 50%;
    padding: 0;
    justify-content: center;

    &:hover {
      opacity: 0.9;
    }
  }
`;
