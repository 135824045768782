/* eslint-disable class-methods-use-this */
import { AxiosRequestConfig } from 'axios';

import { HttpService } from './HttpService';

class GrantoApi extends HttpService {
  constructor(props?: any) {
    const baseUrl =
      props && props.url
        ? `${props.url}/api`
        : `${process.env.REACT_APP_API_HOST}/api`;

    super(baseUrl);
  }

  private getToken() {
    return localStorage.getItem('@Granto:token');
  }

  public setToken(token: string) {
    this.instance.defaults.headers.common.authorization = `Bearer ${token}`;
  }

  public makeHttpRequest(config: AxiosRequestConfig) {
    const token = this.getToken();

    if (token) {
      this.setToken(token as string);
    }

    return super.makeHttpRequest(config);
  }
}

export const grantoApi = new GrantoApi();
