import { connect, useSelector } from 'react-redux';

import { ReactComponent as ImageHome } from 'src/assets/images/home/home.svg';
import { RootState } from 'src/redux/store';

import { Container, CardWelcome, ImageContent } from './styles';

export const Home = () => {
  const { user } = useSelector((state: RootState) => state.user);

  return (
    <Container>
      <CardWelcome>
        <h3>Portal MyGranto</h3>
        <h1>Olá, {user.name}</h1>
        <span>
          MyGranto é um aplicativo desenvolvido para atender às necessidades dos
          nossos clientes na contratação de seguros. Acesse e acompanhe aqui suas demandas com a Granto, apólices, boletos em aberto e muito mais.
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          Aproveite!
        </span>
        <ImageContent>
          <ImageHome />
        </ImageContent>
      </CardWelcome>
    </Container>
  );
};

const mapStateToProps = (state: any) => ({ user: state.user });

export default connect(mapStateToProps)(Home);
