import styled, { css } from 'styled-components';

interface IProps {
  sizeDisplay: '100' | '75' | '66' | '50' | '33' | '25';
}

interface ISelectProps {
  disabled: boolean;
}

export const Container = styled.div<IProps>`
  position: relative;
  display: flex;

  ${(props) =>
    props.sizeDisplay === '100'
      ? css`
          width: 100%;
        `
      : props.sizeDisplay === '75'
      ? css`
          width: calc(75% - 8px);
        `
      : props.sizeDisplay === '66'
      ? css`
          width: calc(66% - 8px);
        `
      : props.sizeDisplay === '50'
      ? css`
          width: calc(50% - 8px);
        `
      : props.sizeDisplay === '33'
      ? css`
          width: calc(33% - 8px);
        `
      : css`
          width: calc(25% - 11px);
        `}
`;

export const Label = styled.label<ISelectProps>`
  position: absolute;
  top: -9px;
  left: 8px;
  color: ${({ disabled }) => (disabled ? '#C2C2C2' : '#52525b')};

  font-family: 'Poppins';
  font-size: 12px;
  line-height: 18px;
  background: #ffffff;
  padding: 0 4px;
  z-index: 1;
`;

export const Icon = styled.div`
  height: 48px;
  position: absolute;
  display: flex;
  align-items: center;
  padding-left: 18px;

  font-size: 22px;
  color: #52525b;
  background-color: #ffff;

  border-top: 1px solid #52525b;
  border-left: 1px solid #52525b;
  border-bottom: 1px solid #52525b;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  z-index: 1;
`;
