/* eslint-disable import/no-extraneous-dependencies */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonListRow } from './styles';

const numbers = [1, 2, 3, 4, 5];

export const SkeletonTemplatePoliciesIssued = () => (
  <SkeletonTheme>
    <SkeletonListRow>
      <Skeleton containerClassName="skeleton-list" inline height={234} />
    </SkeletonListRow>

    <SkeletonListRow className="skeleton-column-list">
      {numbers.map((item) => (
        <Skeleton
          key={item}
          containerClassName="skeleton-button"
          inline
          width={167}
          height={132}
        />
      ))}
    </SkeletonListRow>

    <SkeletonListRow>
      <Skeleton containerClassName="skeleton-list" inline height={86} />
    </SkeletonListRow>
  </SkeletonTheme>
);
