import { useCallback, useEffect, useState, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { CaretLeft } from 'phosphor-react';
import theme from 'src/assets/styles/themes/default';
import { StatusModal, StyledButton, StyledInput } from 'src/components';
import { useAuth } from 'src/hooks/auth';
import { TypeStatus } from 'src/utils/enum';

import { ReactComponent as AuthLogo } from '../../assets/images/logos/auth-logo.svg';
import { PasswordChange, PasswordForgot } from './components';
import {
  BackContainer,
  ButtonsContainer,
  Container,
  FormContainer,
  FormContent,
  FormHeader,
  ImageContainer,
  InputsContainer,
  TextButton,
} from './styles';
import * as fO from './utils/formOptions';
import { FormState, MinHeigthFormState } from './utils/TypeToForm';

export const Login = () => {
  const firstRender = useRef(true);
  const { pathname } = useLocation();

  const { signIn, userLogged } = useAuth();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies([
    '@Granto:remember',
    '@Granto:lastpath',
  ]);

  const [loading, setLoading] = useState(false);
  const [lastPath, setLastPath] = useState();
  const [reminder, setReminder] = useState(false);
  const [formState, setFormState] = useState<FormState>(FormState.SIGNIN);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState<TypeStatus>(TypeStatus.SUCCESS);
  const [visible, setVisible] = useState(false);
  const [minHeighToForm, setMinHeigthToForm] = useState<string>(
    MinHeigthFormState.SIGNIN
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
  });

  const canDisableButton = () => !isValid;

  const handleRedirectToSite = () => {
    if (process.env.REACT_APP_GRANTO_URL_SITE) {
      window.location.href = process.env.REACT_APP_GRANTO_URL_SITE;
    }
  };

  const onSubmit = useCallback(async (data: any) => {
    setLoading(true);
    if (reminder && data) {
      setCookie('@Granto:remember', data, { path: '/' });
    } else {
      removeCookie('@Granto:remember', { path: '/' });
    }

    const result = await signIn(data);

    if (result.status === TypeStatus.SUCCESS) {
      setLoading(false);

      if (cookies['@Granto:lastpath']) {
        navigate(cookies['@Granto:lastpath']);
      } else {
        navigate('/admin/my-policies');
      }
    } else {
      setLoading(false);
      setModal(true);
      setModalMessage('Falha na autenticação, verifique suas credenciais!');
      setModalType(TypeStatus.ERROR);
    }
  }, []);

  useEffect(() => {
    if (firstRender.current) {
      if (cookies['@Granto:remember']) {
        setValue('Email', cookies['@Granto:remember'].email);
        setValue('Password', cookies['@Granto:remember'].password);
        setReminder(true);
      }
      firstRender.current = false;
    }
  }, [firstRender]);

  useEffect(() => {
    if (
      pathname.includes('/auth/recuperar-senha') ||
      pathname.includes('/auth/cadastrar-senha')
    ) {
      setFormState(FormState.CHANGE);
      setMinHeigthToForm(MinHeigthFormState.CHANGE);
    }
  }, [pathname]);

  useEffect(() => {
    if (userLogged.email) {
      if (cookies['@Granto:lastpath']) {
        navigate(cookies['@Granto:lastpath']);
      } else {
        navigate('/admin/my-informations');
      }
    }
  }, [userLogged, lastPath]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <>
      {modal && (
        <StatusModal
          width={450}
          setModal={setModal}
          successTitle="Sucesso"
          errorTitle="Erro"
          text={modalMessage}
          type={modalType}
        />
      )}
      <Container>
        <BackContainer onClick={handleRedirectToSite}>
          <div>
            <CaretLeft size={18} weight="bold" color="white" />
            <span>voltar ao site</span>
          </div>
        </BackContainer>
        <ImageContainer>
          <AuthLogo />
        </ImageContainer>
        <FormContent>
          {formState === FormState.SIGNIN && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormContainer minHeight={minHeighToForm}>
                <FormHeader>
                  <h1>Bem-vindo ao MyGranto</h1>
                  <span>
                    Um novo jeito de gerenciar apólices e ter mais liberdade
                    para tomar decisões.
                  </span>
                </FormHeader>

                <InputsContainer>
                  <StyledInput
                    label="E-mail"
                    placeholder="Digite seu e-mail"
                    sizeDisplay="100"
                    heigth={windowWidth <= 1366 ? '56' : '64'}
                    fontSize={windowWidth <= 1366 ? '18' : '20'}
                    labelSize={windowWidth <= 1366 ? '18' : '20'}
                    register={register}
                    registerProps={{ name: 'Email', options: fO.emailOptions }}
                    error={!!errors.Email}
                    errorMessage={(errors.Email?.message as string) || ''}
                  />
                  <StyledInput
                    type={visible ? 'text' : 'password'}
                    icon={
                      visible ? (
                        <FiEye
                          onClick={() => {
                            setVisible(!visible);
                          }}
                          style={{ cursor: 'pointer' }}
                          color={theme.colors.neutral[400]}
                        />
                      ) : (
                        <FiEyeOff
                          onClick={() => {
                            setVisible(!visible);
                          }}
                          style={{ cursor: 'pointer' }}
                          color={theme.colors.neutral[400]}
                        />
                      )
                    }
                    label="Senha"
                    placeholder="Digite sua senha"
                    sizeDisplay="100"
                    heigth={windowWidth <= 1366 ? '56' : '64'}
                    fontSize={windowWidth <= 1366 ? '18' : '20'}
                    labelSize={windowWidth <= 1366 ? '18' : '20'}
                    register={register}
                    registerProps={{
                      name: 'Password',
                      options: fO.passwordOptions,
                    }}
                    error={!!errors.Password}
                    errorMessage={(errors.Password?.message as string) || ''}
                  />
                </InputsContainer>

                <ButtonsContainer>
                  <TextButton
                    onClick={() => {
                      setMinHeigthToForm(MinHeigthFormState.FORGOT);
                      setFormState(FormState.FORGOT);
                    }}
                  >
                    Esqueceu sua senha?
                  </TextButton>
                  <StyledButton
                    className="btn-submit"
                    text="ACESSAR"
                    type="submit"
                    disabled={canDisableButton()}
                    spinner={loading}
                  />
                </ButtonsContainer>
              </FormContainer>
            </form>
          )}

          {formState === FormState.FORGOT && (
            <PasswordForgot
              setFormState={setFormState}
              minHeighToForm={minHeighToForm}
              setModal={setModal}
              setModalMessage={setModalMessage}
              setModalType={setModalType}
            />
          )}

          {formState === FormState.CHANGE && (
            <PasswordChange
              setFormState={setFormState}
              minHeighToForm={minHeighToForm}
              setModal={setModal}
              setModalMessage={setModalMessage}
              setModalType={setModalType}
            />
          )}
        </FormContent>
      </Container>
    </>
  );
};
