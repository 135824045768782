/* eslint-disable import-helpers/order-imports */

import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from 'src/assets/styles/global';
import defaultTheme from 'src/assets/styles/themes/default';
import App from 'src/pages/App';

import store from './redux/store';

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CookiesProvider>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <App />
          </ThemeProvider>
        </CookiesProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
