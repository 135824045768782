/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-alert */
import { createRef, useState, useEffect } from 'react';

import { Camera } from 'phosphor-react';
import { useAuth } from 'src/hooks/auth';
import { handleToFormatBase64 } from 'src/utils/FormatToBase64';

import { StyledButton } from '../StyledButton';
import { AvatarContent, ButtonContent, DefaultAvatar } from './styles';

interface AvatarProps {
  height?: string;
  width?: string;
  fontSize?: string;
  showButton?: boolean;
  srcImage?: string;
  nameReduce?: string;
  setAvatarChange?: (value: File) => void;
}

export const UserAvatar = ({
  height,
  width,
  fontSize,
  showButton = true,
  srcImage,
  nameReduce,
  setAvatarChange,
}: AvatarProps) => {
  const { userLogged } = useAuth();
  const fileRef = createRef<HTMLInputElement>();

  const [uploaded, setUploaded] = useState(null as string | null);

  const onFileInputChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      handleToFormatBase64(file).then((base64) => {
        setUploaded(base64);
        if (setAvatarChange) {
          setAvatarChange(file);
        }
      });
    }
  };

  useEffect(() => {
    if (srcImage) setUploaded(srcImage);
  }, [srcImage]);

  return (
    <>
      <AvatarContent height={height} width={width}>
        {uploaded ? (
          <img src={uploaded ?? ""} alt={`Avatar do usuário ${userLogged.name}`} />
        ) : (
          <DefaultAvatar height={height} width={width} fontSize={fontSize}>
            <span>{nameReduce}</span>
          </DefaultAvatar>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileRef}
          onChange={onFileInputChange}
          accept="image/png,image/jpeg,image/jpg"
        />
      </AvatarContent>
      <ButtonContent showButton={showButton}>
        <StyledButton
          className="btn-upload-avatar"
          icon={<Camera color="white" size={24} />}
          iconPosition="center"
          onClick={() => fileRef.current?.click()}
          type="button"
        />
      </ButtonContent>
    </>
  );
};
