// import { useEffect, useState } from 'react';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/auth';

const handleLocation = () => {
  const [cookies, setCookie] = useCookies(['@Granto:lastpath']);
  const location = useLocation();

  useEffect(() => {
    setCookie('@Granto:lastpath', location.pathname, { path: '/' });
  }, [location]);
};

const ProtectedRoute = ({ redirectPath = '/', children }: any) => {
  const { userLogged } = useAuth();

  if (!userLogged.email) {
    handleLocation();
    return <Navigate to={redirectPath} replace />;
  }

  return children || <Outlet />;
};

export { ProtectedRoute };
