/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const menuSlice = createSlice({
  name: 'menu',
  initialState: { menu: false },
  reducers: {
    setShowMenu: (state, action) => {
      state.menu = action.payload;
    },
  },
});

export const { setShowMenu } = menuSlice.actions;

export default menuSlice.reducer;
