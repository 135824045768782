import React from 'react';

import { AuthProvider } from './auth';

interface AppProps {
  children: React.ReactNode;
}

const AppContext = ({ children }: AppProps) => (
  <AuthProvider>{children}</AuthProvider>
);

export { AppContext };
