import { ReactElement } from 'react';

import { Container } from './styles';

interface IProps {
  title: string;
  subtitle?: string;
  icon?: ReactElement;
}

export const AdminTitle = ({ title, subtitle, icon }: IProps) => (
  <Container>
    {icon}
    <h1>{title}</h1>
    <h3>{subtitle}</h3>
  </Container>
);
