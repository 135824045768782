// eslint-disable-next-line import/no-extraneous-dependencies
import { Tooltip as ReactTooltip, PlacesType } from "react-tooltip";

interface IProps {
  id: string;
  place?: PlacesType;
  content: string;
}

export const TooltipElement = ({ id, place = 'top', content }: IProps) => (
  <ReactTooltip
    id={id}
    place={place}
    content={content}
  />
);
