/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useCallback, createRef } from 'react';
import { StylesConfig } from 'react-select';

import { ArrowLeft, MagnifyingGlass, Paperclip } from 'phosphor-react';
import theme from 'src/assets/styles/themes/default';
import { TransferDocumentFunction } from 'src/interfaces';

import { StyledButton } from '../StyledButton';
import { StyledSelect } from '../StyledSelect';
import {
  Container,
  Content,
  ErrorText,
  LabelInput,
  FileLabel,
  Text,
  Title,
  ButtonContainer,
  FileInputContainer,
  TypeInputContainer,
} from './styles';

interface IProps {
  setModalDocument: (value: boolean) => void;
  setDocument: ({
    documentFile,
    documentFileName,
  }: TransferDocumentFunction) => void;
}

const allowedExtensions = ['pdf'];

const DocumentModal = ({ setDocument, setModalDocument }: IProps) => {
  const fileRef = createRef<HTMLInputElement>();

  const [documentFile, setDocumentFile] = useState<File>({} as File);
  const [documentFileName, setDocumentFileName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleDocumentChange = useCallback(
    (e: any) => {
      if (!e.target.files.length) return;

      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split('/')[1];

      if (!allowedExtensions.includes(fileExtension)) {
        setErrorMessage("O Formato do arquivo tem que ser '.pdf'");
        return;
      }

      setErrorMessage('');
      setDocumentFile(inputFile);
      setDocumentFileName(inputFile.name);
    },
    [documentFile, documentFileName]
  );

  const onSubmit = () => {
    setDocument({ documentFile, documentFileName });
    setModalDocument(false);
  };

  const fileSelectStyle: StylesConfig = {
    container: (base) => ({
      ...base,
      width: '100%',
      boxShadow: 'none',
    }),
    control: (base, state) => ({
      ...base,
      height: `64px`,
      background: theme.colors.white,
      borderTop: `1px solid ${
        state.isDisabled
          ? theme.colors.neutral[600]
          : theme.colors.border.primary
      }`,
      borderRight: `1px solid ${
        state.isDisabled
          ? theme.colors.neutral[600]
          : theme.colors.border.primary
      }`,
      borderBottom: `1px solid ${
        state.isDisabled
          ? theme.colors.neutral[600]
          : theme.colors.border.primary
      }`,
      borderLeft: `1px solid ${
        state.isDisabled
          ? theme.colors.neutral[600]
          : theme.colors.border.primary
      }`,
      borderRadius: '8px',
      fontFamily: 'Mulish',
      fontWeight: 500,
      fontSize: '20px',
      boxShadow: 'none',
      '&:hover': {
        borderTop: `1px solid ${theme.colors.border.primary}`,
        borderRight: `1px solid ${theme.colors.border.primary}`,
        borderBottom: `1px solid ${theme.colors.border.primary}`,
        borderLeft: `1px solid ${theme.colors.border.primary}`,
      },
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      visibility: state.hasValue ? 'visible' : 'hidden',
      marginBottom: '15px',
      marginTop: '15px',
    }),
    placeholder: (base) => ({
      ...base,
      fontFamily: 'Mulish',
      fontSize: '20px',
      fontWeight: 500,
      color: theme.colors.neutral[400],
    }),
  };

  return (
    <Container>
      <Content>
        <Title>Anexar documento</Title>
        <Text>Você pode anexar um arquivo .pdf de até 125MB.</Text>
        <form>
          <div className="inputWrapper">
            <TypeInputContainer>
              <LabelInput>Tipo</LabelInput>
              <StyledSelect
                options={[
                  {
                    value: 'batatinha',
                    label: 'Teste',
                  },
                ]}
                placeholder="Tipo de documento"
                setValue={(val) => val}
                sizeDisplay="100"
                sizeHeight="64"
                style={fileSelectStyle}
              />
            </TypeInputContainer>

            <FileInputContainer>
              <LabelInput>Documento</LabelInput>
              <FileLabel hasDocument={!documentFileName}>
                <input
                  onChange={handleDocumentChange}
                  ref={fileRef}
                  name="file"
                  type="File"
                />

                <p>
                  {' '}
                  {!documentFileName
                    ? 'Selecionar Arquivo'
                    : `${documentFileName} `}
                </p>

                <StyledButton
                  type="button"
                  className="search-document"
                  iconPosition="left"
                  icon={<MagnifyingGlass size={20} weight="bold" />}
                  text="Pesquisar"
                  onClick={() => fileRef.current?.click()}
                />
              </FileLabel>
            </FileInputContainer>
          </div>

          <ErrorText>{errorMessage}</ErrorText>
        </form>

        <ButtonContainer hasDocument={!documentFileName}>
          <StyledButton
            type="button"
            className="modal-button"
            iconPosition="left"
            icon={<ArrowLeft size={24} weight="bold" />}
            text="Voltar"
            onClick={() => setModalDocument(false)}
          />
          <StyledButton
            type="button"
            iconPosition="left"
            icon={<Paperclip size={26} />}
            className="modal-button submit"
            text="Anexar documento"
            onClick={() => (documentFileName ? onSubmit() : '')}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
};

export { DocumentModal };
