import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  border-radius: 16px;
  border: 1.5px solid ${({ theme }) => theme.colors.border.primary};
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  border-radius: 16px;

  table {
    width: 100%;
    text-align: center;
    border-radius: 10px;
    color: ${({ theme }) => theme.colors.neutral[200]};

    border-collapse: collapse;
  }

  th {
    padding: 8px;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.neutral[100]};
    background-color: ${({ theme }) => theme.colors.background.primary};
  }

  td {
    padding: 8px;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-weight: 700;
      font-size: 10px;
      line-height: 24px;
      color: #ffffff;
      padding: 0 18px;
      border-radius: 4px;

      &.warning {
        background-color: var(--warning-color);
      }

      &.info {
        background-color: var(--info-color);
      }

      &.danger {
        background-color: var(--danger-color);
      }

      &.success {
        background-color: var(--success-color);
      }
    }
  }

  tbody {
    tr:last-child {
      border-bottom: none;
    }
  }

  tr {
    height: 48px;
    border: 0.5px solid ${({ theme }) => theme.colors.neutral[600]};
    border-left: none;
    border-right: none;

    cursor: pointer;
  }

  tr:nth-child(1) {
    border-top: none;
  }

  tr:nth-child(odd) {
    background-color: ${({ theme }) => theme.colors.white};
  }

  tr:nth-child(even) {
    background-color: ${({ theme }) => theme.colors.background.primary};
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;
