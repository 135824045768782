/* eslint-disable import/no-extraneous-dependencies */
import Skeleton from 'react-loading-skeleton'

import 'react-loading-skeleton/dist/skeleton.css'

interface IProps {
  height?: number;
  width?: number;
}

export const SkeletonCircle = ({ height = 120, width = 120 }: IProps) => (
  <Skeleton style={{marginBottom:8}} circle width={width} height={height} />
);
