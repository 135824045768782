import { useEffect, useState } from 'react';

import { CaretLeft, CaretRight } from 'phosphor-react';
import theme from 'src/assets/styles/themes/default';

import { ButtonsContainer, Container } from './styles';

interface IPaginationButton {
  itemsPerPage: number;
  setItemsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  totalItems: number;
}

export const PaginationButtons: React.FC<IPaginationButton> = ({
  itemsPerPage,
  setItemsPerPage,
  page,
  setPage,
  totalItems,
}) => {
  const [isLastPage, setIsLastPage] = useState(false);
  const itemCalc = page * itemsPerPage;

  useEffect(() => {
    if (
      totalItems &&
      itemsPerPage &&
      page &&
      page * itemsPerPage > totalItems
    ) {
      setIsLastPage(true);
    } else {
      setIsLastPage(false);
    }
  }, [totalItems, itemsPerPage, page]);

  return (
    <Container>
      <ButtonsContainer>
        <button
          type="button"
          aria-label="Esquerda"
          onClick={
            setPage && page && page > 1
              ? () => setPage(page ? page - 1 : 1)
              : undefined
          }
          disabled={page === 1}
        >
          <CaretLeft
            size={18}
            weight="bold"
            color={
              page === 1 ? theme.colors.neutral[300] : theme.colors.blue.primary
            }
          />
        </button>
      </ButtonsContainer>
      {(page - 1) * itemsPerPage + 1} -{' '}
      {itemCalc > totalItems ? totalItems : itemCalc} de {totalItems}
      <ButtonsContainer>
        <button
          type="button"
          aria-label="Direita"
          onClick={
            setPage && !isLastPage
              ? () => setPage(page ? page + 1 : 1)
              : undefined
          }
          disabled={isLastPage}
        >
          <CaretRight
            size={18}
            weight="bold"
            color={
              isLastPage ? theme.colors.neutral[300] : theme.colors.blue.primary
            }
          />
        </button>
      </ButtonsContainer>
    </Container>
  );
};
