import { PaginationButtons } from './components';
import { Container, Footer, TableContainer } from './styles';

interface ITable {
  heads: string[];
  children?: JSX.Element[] | JSX.Element;
  hasPagination: boolean;
  itemsPerPage: number;
  setItemsPerPage?: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  totalItems: number;
}

export const DataTable: React.FC<ITable> = ({
  heads,
  children,
  hasPagination,
  itemsPerPage,
  setItemsPerPage,
  page,
  setPage,
  totalItems,
}) => (
  <div>
    <Container>
      <TableContainer>
        <table>
          <thead>
            <tr>
              {heads.map((head) => (
                <th key={head}>
                  <div>{head}</div>
                </th>
              ))}
            </tr>
          </thead>
          {children}
        </table>
      </TableContainer>
    </Container>
    {hasPagination && (
      <Footer>
        <PaginationButtons
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          page={page}
          setPage={setPage}
          totalItems={totalItems}
        />
      </Footer>
    )}
  </div>
);
