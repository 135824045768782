import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .headerBarContainer {
    width: 100%;
    display: flex;
    gap: 16px;
    margin-top: 32px;
  }

  @media only screen and (max-width: 480px) {
    gap: 12px;

    .headerBarContainer {
      width: 100%;
      flex-direction: column;
      gap: 8px;
      margin-top: 8px;
    }
  }
`;

export const Row = styled.tr`
  &:hover {
    td {
      &:nth-child(1) {
        color: ${({ theme }) => theme.colors.blue.primary};
      }
    }
  }

  td {
    p {
      min-width: 110px;
      border-radius: 4px;
      font-weight: 700;
    }

    .in-progress {
      color: ${({ theme }) => theme.colors.yellow};
      background-color: ${({ theme }) => theme.colors.opacity.yellow};
    }
    .in-force {
      color: ${({ theme }) => theme.colors.green.success};
      background-color: ${({ theme }) => theme.colors.opacity.green};
    }
    .defeated {
      color: ${({ theme }) => theme.colors.red};
      background-color: ${({ theme }) => theme.colors.opacity.red};
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
