import styled from 'styled-components';

interface IProps {
  breadcrumbs: boolean;
}

export const Container = styled.header<IProps>`
  background-color: ${({ theme }) => theme.colors.white};
  height: 72px;

  display: flex;
  justify-content: ${({ breadcrumbs }) =>
    breadcrumbs ? 'space-between' : 'flex-end'};

  padding: 16px 56px;

  .dropdown {
    position: relative;
  }

  @media only screen and (max-width: 480px) {
    padding: 8px;
  }
`;

export const BackContainer = styled.div`
  display: flex;

  div {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  span {
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.blue.primary};
    transition: 0.2s ease;

    &:hover {
      opacity: 0.9;
    }
  }

  .my-policy {
    color: ${({ theme }) => theme.colors.neutral[100]};
  }

  @media only screen and (max-width: 480px) {
    .mobileHide {
      display: none;
    }
  }
`;

export const MenuIconContainer = styled.div`
  display: flex;

  div {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  @media only screen and (max-width: 480px) {
    position: absolute;
    left: 24px;
    top: 16px;
  }

  @media only screen and (min-width: 481px) {
    display: none;
  }
`;

export const Header = styled.div`
  h3 {
    color: ${({ theme }) => theme.colors.blue.primary};
  }
`;

export const AvatarContainer = styled.div`
  display: grid;
  justify-content: center;
  gap: 12px;
  margin: 25px 0;

  label {
    text-decoration: underline;
    text-align: center;
    color: ${({ theme }) => theme.colors.blue.primary};
    cursor: pointer;
  }

  .updateAvatar {
    input[type='file'] {
      display: none;
    }

    label {
      text-align: center;
      display: block;
      margin: 6px 0 0;
      font-size: 12px;
      color: #000;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const Avatar = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: 80px;
  width: 80px;
  margin: 0;
  background: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const InputsContainer = styled.div`
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  gap: 35px;
`;

export const ButtonContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;

  p {
    color: ${({ theme }) => theme.colors.neutral[300]};
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`;

export const Button = styled.div`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.neutral[100]};

  &:hover {
    p {
      color: ${({ theme }) => theme.colors.blue.secondary};
      text-decoration: underline;
    }
  }
`;

export const UserAvatarContent = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  cursor: pointer;

  @media only screen and (max-width: 480px) {
    .userName {
      display: none;
    }
  }
`;

export const MiniAvatar = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;

  background-color: black;
`;

export const Dot = styled.div`
  position: absolute;
  top: -3px;
  left: 18px;
  height: 22px;
  width: 22px;
  background-color: ${({ theme }) => theme.colors.red};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  span {
    font-weight: 700;
    font-size: 12.5px;
    color: ${({ theme }) => theme.colors.white};
  }
`;
