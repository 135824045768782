export const handleReduceUserName = (value: string) => {
  let nameReduced;

  if (value) {
    const arrayName = value.split(' ');
    nameReduced = arrayName.reduce(
      (nameAccumulated, name, index) =>
        index < 2 ? nameAccumulated + name.slice(0, 1) : nameAccumulated,
      ''
    );
  }

  return nameReduced;
};
