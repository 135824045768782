import { handleReduceUserName } from './ReduceUserName';

export const FormatUser = (data: any) => {
  const user = {
    ...data,
    name: data.nome,
    nameReduce: handleReduceUserName(data.nome),
    avatar: data.imagemPerfil,
  };

  return user;
};
