import styled from 'styled-components';

interface IProps {
  hasDocument?: boolean;
}

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: #00000065;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  min-width: 800px;
  background: ${({ theme }) => theme.colors.white};

  box-shadow: ${({ theme }) => theme.shadow.default};
  border-radius: 10px;
  padding: 48px;

  display: flex;
  flex-direction: column;

  a {
    font-size: 16px;
  }

  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 29px;

    .inputWrapper {
      display: flex;
      gap: 16px;
    }

    button {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 45px;

      font-weight: 400;
    }
  }
`;

export const Title = styled.p`
  font-size: 36px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.neutral[100]};

  margin-bottom: 24px;
`;

export const Text = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral[300]};

  margin-bottom: 32px;
`;

export const TypeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 280px;
`;

export const FileInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 500px;
`;

export const LabelInput = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.neutral[200]};
`;

export const FileLabel = styled.label<IProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 64px;
  padding: 16px 24px;

  font-size: 20px;
  font-weight: 500;
  color: ${({ theme, hasDocument }) =>
    hasDocument ? theme.colors.neutral[500] : theme.colors.neutral[100]};
  cursor: pointer;

  border-radius: 8px;
  border: 1.5px solid ${({ theme }) => theme.colors.border.primary};

  &:hover {
    opacity: 0.8;
  }

  .search-document {
    width: 158px;
    height: 32px;

    font-weight: 700;
    font-size: 14px;

    padding: 11px 20px;
    background-color: ${({ theme }) => theme.colors.blue.secondary};
    border-radius: 20px;
  }

  > input {
    display: none;
  }
`;

export const ErrorText = styled.p`
  font-weight: 500;
  text-align: center;

  color: ${({ theme }) => theme.colors.red};
`;

export const ButtonContainer = styled.div<IProps>`
  width: 100%;
  display: flex;
  justify-content: center;

  gap: 16px;

  .modal-button {
    min-width: 280px;
    height: 64px;

    display: flex;
    justify-content: center;

    font-size: 20px;
    font-weight: 600;

    background-color: ${({ theme }) => theme.colors.blue.secondary};
  }

  .submit {
    background-color: ${({ theme, hasDocument }) =>
      hasDocument ? theme.colors.neutral[500] : theme.colors.blue.secondary};
  }
`;
