import { TbLogout } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';

import theme from 'src/assets/styles/themes/default';
import { useAuth } from 'src/hooks/auth';
import { setShowMenu } from 'src/redux/slices/mobileMenu';
import store from 'src/redux/store';

import SidebarData, { ISibebar } from '../../utils/SidebarData';
import { AsideButton } from './components/AsideButton';
import { BackContainer, Container, Logout } from './styles';

export const AsideMobileMenu = () => {
  const { userLogged, signOut } = useAuth();

  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate('/');
    store.dispatch(setShowMenu(false));
  };

  return (
    <>
      <Container>
        <div className="logoContainer">
          <Link to="/admin/my-policies">
            <div className="miniLogo" />
            <div className="hoverLogo" />
          </Link>
        </div>

        <div>
          <ul>
            {SidebarData.map((data: ISibebar) =>
              data.path ? (
                <Link
                  onClick={() => store.dispatch(setShowMenu(false))}
                  to={data.path}
                  key={data.id.toString()}
                >
                  <AsideButton
                    title={data.title}
                    icon={data.icon}
                    path={data.path}
                  />
                </Link>
              ) : (
                <AsideButton
                  key={data.id.toString()}
                  title={data.title}
                  icon={data.icon}
                  path={data.path}
                />
              )
            )}
          </ul>
        </div>
        <Logout onClick={handleLogout}>
          <AsideButton
            isLogout
            title="Sair"
            icon={<TbLogout color={theme.colors.red} />}
            path=""
          />
        </Logout>
      </Container>
      <BackContainer onClick={() => store.dispatch(setShowMenu(false))} />
    </>
  );
};
