import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%; 
  flex-direction: row;
`;

export const SkeletonListRow = styled.div`
  width: 100%; 
  display: flex;
  flex-direction: row;
  
  .skeleton-column-list {
    justify-content: space-between;
  }

  .skeleton-list {
    width: 100%; 
    display: flex;
    flex-direction: row;
    margin-bottom:8px;
    justify-content: center;
  }

  .skeleton-button{
    margin-bottom:8px;
    margin-right: 16px;
  }

  .skeleton-button:last-child {
    margin-right: 0px;
  }
`;