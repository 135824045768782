export enum FormState {
  SIGNIN = 'SignIn',
  FORGOT = 'Forgot',
  CHANGE = 'Change',
}

export enum MinHeigthFormState {
  SIGNIN = '608',
  FORGOT = '488',
  CHANGE = '640',
}
