/* eslint-disable react/jsx-props-no-spreading */
import { ButtonHTMLAttributes, ReactElement } from 'react';

import { SpinnerElement } from '../Spinner';
import { Button } from './styles';

interface IProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  icon?: ReactElement;
  iconPosition?: 'left' | 'right' | 'center';
  iconWhiteBackground?: boolean;
  iconBorderRadius?: boolean;
  color?: string;
  spinner?: boolean;
}

export const StyledButton: React.FC<IProps> = ({
  color,
  text,
  icon,
  iconPosition,
  iconWhiteBackground = false,
  iconBorderRadius = false,
  disabled = false,
  spinner = false,
  ...rest
}) => (
  <Button
    svgRadius={iconBorderRadius}
    svgWhiteBackground={iconWhiteBackground}
    disabled={disabled}
    color={color}
    {...rest}
  >
    {iconPosition === 'left' && icon}
    {iconPosition === 'center' && !text && icon}
    {text}
    {spinner && <SpinnerElement iconWhiteBackground={iconWhiteBackground} loading={spinner}  />}
    {iconPosition === 'right' && icon}
  </Button>
);
