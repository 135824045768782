/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useNavigate } from 'react-router';

import { CheckCircle, Warning, WarningCircle } from 'phosphor-react';
import theme from 'src/assets/styles/themes/default';
import { TypeStatus } from 'src/utils/enum';

import { StyledButton } from '../StyledButton';
import { Container, Content } from './styles';

interface IProps {
  type: TypeStatus;
  width?: number;
  successTitle?: string;
  errorTitle?: string;
  warningTitle?: string;
  text: string;
  setModal: (value: boolean) => void;
  navigateTo?: string;
  setCleanForm?: () => void;
}

const StatusModal = ({
  successTitle,
  errorTitle,
  warningTitle,
  text,
  type,
  width,
  setModal,
  navigateTo,
  setCleanForm,
}: IProps) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Content width={width} type={type}>
        {type === TypeStatus.SUCCESS && (
          <>
            <CheckCircle
              color={theme.colors.green.success}
              weight="bold"
              size={36}
            />

            <h3>{successTitle}</h3>

            <p>{text}</p>

            <StyledButton
              className="buttonClose"
              color={theme.colors.blue.secondary}
              text="Fechar"
              iconWhiteBackground
              iconBorderRadius
              onClick={() => {
                setModal(false);
                navigate(navigateTo!);
              }}
            />
          </>
        )}

        {type === TypeStatus.ERROR && (
          <>
            <WarningCircle color={theme.colors.red} weight="bold" size={32} />

            <h3>{errorTitle}</h3>

            <p>{text}</p>

            <StyledButton
              className="buttonClose"
              color={theme.colors.blue.secondary}
              text="Fechar"
              iconWhiteBackground
              iconBorderRadius
              onClick={() => {
                setModal(false);
                navigate(navigateTo!);
              }}
            />
          </>
        )}

        {type === TypeStatus.WARNING && (
          <>
            <Warning color={theme.colors.yellow} weight="bold" size={32} />

            <h3 style={{ color: theme.colors.yellow }}>{warningTitle}</h3>

            <p>{text}</p>

            <StyledButton
              className="buttonClose"
              color={theme.colors.blue.secondary}
              text="Fechar"
              iconWhiteBackground
              iconBorderRadius
              onClick={() => {
                setModal(false);
                navigate(navigateTo!);
              }}
            />
          </>
        )}
      </Content>
    </Container>
  );
};

export { StatusModal };
