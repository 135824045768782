export const CurrencyNormalizer = (value: string) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('pt-BR', options).format(
    parseFloat(onlyNums) / 100
  );

  return `R$ ${result}`;
};

export const DateNormalizer = (value: string) => {
  if (!value) return value;
  const onlyNums = value.replace(/[^\d]/g, '');

  return onlyNums
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\/\d{4})\d+?$/, '$1');
};

export const convertNumber = (val: number) =>
  val < 10 ? `0${val.toString()}` : val.toString();
