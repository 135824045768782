import styled from 'styled-components';

interface IProps {
  active: boolean;
  isLogout: boolean | undefined;
}

export const Container = styled.div<IProps>`
  position: relative;
  padding: 0 24px 8px;

  ul {
    height: 100%;
  }

  .menu {
    width: 100%;
    height: 47px;

    display: flex;
    align-items: center;
    gap: 15px;
    padding: 0 6px;
    transition: 0.3s ease;
    border-radius: 10px;

    background-color: ${({ active, theme }) =>
      active ? theme.colors.blue.quaternary : ''};

    div {
      display: flex;
      font-size: 1.8rem;
      color: ${({ theme }) => theme.colors.neutral[100]};
    }

    p {
      display: none;

      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: ${({ isLogout, theme }) =>
        isLogout ? theme.colors.red : theme.colors.neutral[100]};

      white-space: nowrap;
      transition: 0.6s ease;
    }
  }
`;
