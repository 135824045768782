import { Map } from 'src/interfaces';

export interface Notification {
  id: string;
  idUser: number;
  idPolicy: number;
  idCompany: number;
  title: string;
  description: string;
  read?: Date;
  createAt: Date;
  toggle: boolean;
  elapsedTimeText: string;
  elapsedDaysText: string;
}

export const titles: Map = {
  hoje: 'HOJE',
  ontem: 'ONTEM',
  mais_antiga: 'MAIS ANTIGAS',
};
