/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { User } from 'src/interfaces';

type UserState = {
  user: User;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: { user: {} } as UserState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
