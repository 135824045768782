import { IOptions } from 'src/types';

export const emailOptions: IOptions = {
  required: { value: true, message: 'Campo obrigatório!' },
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: 'Email inválido!',
  },
};

export const passwordOptions: IOptions = {
  required: { value: true, message: 'Campo obrigatório!' },
  minLength: { value: 6, message: 'Tamanho mínimo é 6!' },
};

export const changePasswordOptions: IOptions = {
  minLength: { value: 6, message: 'Tamanho mínimo é 6!' },
};
