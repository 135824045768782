import { useLocation } from 'react-router-dom';

import { Container } from './styles';

interface AsideButtonProps {
  icon: string | JSX.Element;
  title: string;
  path: string;
  isLogout?: boolean;
}

export const AsideButton: React.FC<AsideButtonProps> = ({
  title,
  icon,
  path,
  isLogout,
  ...rest
}) => {
  const { pathname } = useLocation();

  let activedMenu = false;
  if (path.length) {
    activedMenu = pathname?.includes(path);
  }

  return (
    <Container active={activedMenu} isLogout={isLogout} {...rest}>
      <li className="menu">
        <div>{icon}</div>
        <p>{title}</p>
      </li>
    </Container>
  );
};
