import { connect } from 'react-redux';
import { useNavigate } from 'react-router';

import { ArrowLeft } from 'phosphor-react';
import { AdminTitle, StyledButton } from 'src/components';

import { BackContainer, Container } from './styles';

export const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <AdminTitle
        title="Página não encontrada"
        subtitle="OOPS! Não conseguimos encontrar a página que você está procurando"
      />

      <BackContainer>
        <StyledButton
          iconPosition="left"
          icon={<ArrowLeft size={20} weight="bold" />}
          text="Voltar"
          onClick={() => navigate('my-policies')}
        />
      </BackContainer>
    </Container>
  );
};
