import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 8px;

  h1 {
    font-weight: 700;
    font-size: 48px;
    color: ${({ theme }) => theme.colors.neutral[100]};
  }

  h3 {
    font-weight: 500;
    font-size: 20px;
    color: ${({ theme }) => theme.colors.neutral[300]};
  }

  @media only screen and (max-width: 480px) {
    h1 {
      font-size: 24px;
    }

    h3 {
      font-size: 12px;
    }
  }
`;
