import { useCallback, useEffect, useState } from 'react';
import { RxCopy } from 'react-icons/rx';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import {
  DownloadSimple,
  FileMinus,
  FileText,
  Paperclip,
  UmbrellaSimple,
  Warning,
} from 'phosphor-react';
import {
  AdminTitle,
  DocumentModal,
  StatusModal,
  StyledButton,
  Timeline,
} from 'src/components';
import { SkeletonTemplatePoliciesIssued } from 'src/components/Skeleton/SkeletonTemplatePoliciesIssued';
import { TooltipElement } from 'src/components/Tooltip';
import { useAuth } from 'src/hooks/auth';
import {
  classNameParcel,
  ICardPolicy,
  IParcel,
  Policies,
  policyPageTitle,
  StepTimeline,
  TransferDocumentFunction,
} from 'src/interfaces';
import {
  ActionButton,
  ButtonsContainer,
  Divisor,
  Parcel,
  ParcelDivisor,
  ParcelInfo,
  ParcelList,
  ParcelStatus,
  TimelineContainer,
} from 'src/pages/admin/pages/MyPolicies/components/PolicyDetail/styles';
import { Container } from 'src/pages/admin/pages/MyPolicies/styles';
import { setPolicy } from 'src/redux/slices/policy';
import store, { RootState } from 'src/redux/store';
import { grantoApi } from 'src/services';
import { convertNumber, ErrorMessages, FormatDateTolist } from 'src/utils';
import {
  PolicyStatus,
  TypesDocument,
  TypeStatus,
  ParcelStatusEnum,
} from 'src/utils/enum';
import { FormatPolicyToCard } from 'src/utils/FormatPolicyToCard';

import { CardPolicy } from '../CardPolicy';

export const PolicyDetail = () => {
  const { policyId } = useParams();
  const { policy } = useSelector((state: RootState) => state.policy);
  const { userLogged } = useAuth();

  const [loading, setLoading] = useState(false);
  const [modalDocument, setModalDocument] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalType, setModalType] = useState<TypeStatus>(TypeStatus.SUCCESS);
  const [modalNavigate, setModalNavigate] = useState<string>();

  const [policyDetail, setPolicyDetail] = useState<Policies>();
  const [policyCard, setPolicyCard] = useState<ICardPolicy[]>([]);
  const [policyStatus, setPolicyStatus] = useState<string>('');
  const [stepsTimeline, setStepsTimeline] = useState<StepTimeline[]>([]);
  const [parcels, setParcels] = useState<IParcel[]>([]);
  const [parcelIdSelected, setParcelIdSelected] = useState<number>();

  const handleGetPolicy = async () => {
    try {
      setLoading(true);

      const response = await grantoApi.makeHttpRequest({
        method: 'GET',
        url: `/policies/${policyId}`,
      });

      const policyData = response.data;

      const result = FormatPolicyToCard(policyData);

      setPolicyCard(result);
      setPolicyDetail(policyData);
      setPolicyStatus(policyData.status);

      if (policyData.status === PolicyStatus.INPROGRESS) {
        handleGetTimeline(policyData.id);
      }

      if (policyData.status === PolicyStatus.INFORCE) {
        handleGetParcels(policyData.id);
      }

      store.dispatch(
        setPolicy({
          id: policyData.id,
          status: policyData.status,
        })
      );

      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      setModal(true);
      setModalType(TypeStatus.ERROR);
      setModalNavigate('/admin/my-policies');
      setModalMessage(
        error.response.status === 404
          ? 'Não foi possivel encontrar este item. Entre em contato com o adminstrador para mais informações.'
          : 'Ocorreu um erro ao buscar o item. Tente novamente mais tarde!'
      );
    }
  };

  const handleGetTimeline = async (id: number) => {
    try {
      setLoading(true);
      const response = await grantoApi.makeHttpRequest({
        method: 'GET',
        url: `/policies/list-steps/${id}`,
      });

      setStepsTimeline(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetParcels = async (id: number) => {
    try {
      setLoading(true);
      const response = await grantoApi.makeHttpRequest({
        method: 'GET',
        url: `/parcel/list/${id}`,
      });

      setParcels(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetTicketParcel = async (parcel: IParcel) => {
    try {
      setLoading(true);
      const response = await grantoApi.makeHttpRequest({
        method: 'GET',
        url: `/parcel/document/${parcel.userId}/${parcel.id}`,
      });

      if (response.status === 200) {
        window.location.href = response.data;
      } else if (response.status === 204) {
        setModal(true);
        setModalType(TypeStatus.WARNING);
        setModalMessage('Nenhum boleto encontrado para download desta parcela');
        setModalNavigate('');
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleParcelSelected = (value: IParcel) => {
    setParcelIdSelected(value.id);
    setModalDocument(true);
  };

  const handleSubmitDocument = useCallback(
    async (value: TransferDocumentFunction) => {
      try {
        setLoading(true);
        const formData = new FormData();
        const parcelId = parcelIdSelected as number;

        formData.append('File', value.documentFile as File);
        formData.append('UserId', userLogged.id.toString());
        formData.append('PolicyId', policy.id.toString());
        formData.append('ParcelId', parcelId.toString());
        formData.append('Type', TypesDocument.PARCEL);

        await grantoApi.makeHttpRequest({
          method: 'POST',
          url: '/parcel/save-document',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        setModalDocument(false);
        setModal(true);
        setModalType(TypeStatus.SUCCESS);
        setModalMessage('Documento enviado com sucesso!');
        setModalNavigate('');
        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        setModalDocument(false);
        setModal(true);
        setModalType(TypeStatus.ERROR);
        setModalNavigate('');
        setModalMessage(ErrorMessages(error.response.data.errorCode));
      }
    },
    [parcelIdSelected]
  );

  const actionsButton = [
    {
      name: 'Apólice',
      icon: <UmbrellaSimple size={45} />,
      downloadLink: policyDetail?.policyDoc,
    },
    {
      name: 'Certidão Adm',
      icon: <FileMinus size={45} />,
      downloadLink: policyDetail?.admCertificateDoc,
    },
    {
      name: 'Certidão Regularidade',
      icon: <FileText size={45} />,
      downloadLink: policyDetail?.regularityCertificateDoc,
    },
    {
      name: 'Solicitar Correção',
      icon: <Warning size={45} />,
      disabled: true,
    },
    {
      name: 'Solicitar Endosso',
      icon: <RxCopy size={40} />,
      disabled: true,
    },
  ];

  useEffect(() => {
    handleGetPolicy();
  }, []);

  return (
    <>
      {modalDocument && (
        <DocumentModal
          setDocument={handleSubmitDocument}
          setModalDocument={setModalDocument}
        />
      )}
      {modal && (
        <StatusModal
          width={550}
          successTitle="Sucesso"
          errorTitle="Erro"
          warningTitle="Atenção"
          setModal={setModal}
          text={modalMessage}
          type={modalType}
          navigateTo={modalNavigate}
        />
      )}
      <Container>
        <AdminTitle
          title={policyPageTitle[policyDetail?.status as string] as string}
          subtitle=""
        />

        {loading && <SkeletonTemplatePoliciesIssued />}

        <>
          <CardPolicy
            policy={policyCard}
            policyStatus={policyStatus}
            policyContract={policyDetail?.contractNumber}
          />

          {policyDetail?.status === PolicyStatus.INFORCE && (
            <>
              <ButtonsContainer>
                {actionsButton.map((data) => (
                  <div key={data.name}>
                    <ActionButton
                      disabled={data.disabled}
                      key={data.name}
                      data-tooltip-id={data.name}
                      href={data.downloadLink}
                      target="_blank"
                      download
                    >
                      {data.icon}
                      <p>{data.name}</p>
                    </ActionButton>
                    <TooltipElement id={data.name} content={data.name} />
                  </div>
                ))}
              </ButtonsContainer>

              <ParcelDivisor>
                <h2>Parcelas</h2>
                <Divisor />
              </ParcelDivisor>

              <ParcelList>
                {parcels.map((parcel, index) => (
                  <Parcel
                    key={parcel.id}
                    disabled={parcel.status === ParcelStatusEnum.PAID}
                  >
                    <div>
                      <h2>Parcela {convertNumber(index + 1)}</h2>
                    </div>

                    <ParcelInfo>
                      <ParcelStatus>
                        <div>
                          <span>Vencimento</span>
                          <p>{FormatDateTolist(parcel.vigencyDate)}</p>
                        </div>
                        <div>
                          <span>Status</span>
                          <p
                            className={classNameParcel[parcel.status as string]}
                          >
                            {parcel.status}
                          </p>
                        </div>
                      </ParcelStatus>

                      <ButtonsContainer>
                        <StyledButton
                          className="parcel-button"
                          iconPosition="left"
                          icon={<DownloadSimple size={26} />}
                          text="Baixar boleto"
                          onClick={() =>
                            parcel.status !== ParcelStatusEnum.PAID
                              ? handleGetTicketParcel(parcel)
                              : ''
                          }
                        />
                        <StyledButton
                          className="parcel-button"
                          iconPosition="left"
                          icon={<Paperclip size={26} />}
                          text="Anexar comprovante"
                          onClick={() =>
                            parcel.status !== ParcelStatusEnum.PAID
                              ? handleParcelSelected(parcel)
                              : ''
                          }
                        />
                      </ButtonsContainer>
                    </ParcelInfo>
                  </Parcel>
                ))}
              </ParcelList>
            </>
          )}

          {policyDetail?.status === PolicyStatus.INPROGRESS && (
            <TimelineContainer>
              <Timeline policy={policy} steps={stepsTimeline} />
            </TimelineContainer>
          )}

          {policyDetail?.status === PolicyStatus.DEFEATED && (
            <ButtonsContainer>
              {actionsButton.map((data) => (
                <ActionButton
                  disabled={data.disabled}
                  key={data.name}
                  href={data.downloadLink}
                  target="_blank"
                  download
                >
                  {data.icon}
                  <p>{data.name}</p>
                </ActionButton>
              ))}
            </ButtonsContainer>
          )}
        </>
      </Container>
    </>
  );
};

const mapStateToProps = (state: any) => ({ policy: state.policy });

export default connect(mapStateToProps)(PolicyDetail);
