/* eslint-disable react/no-array-index-key */
/* eslint-disable no-debugger */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useCallback, createRef } from 'react';

import { ArrowLeft, Paperclip } from 'phosphor-react';
import { TransferDocuments } from 'src/interfaces';

import { StyledButton } from '../StyledButton';
import { StyledInput } from '../StyledInput';
import {
  Container,
  Content,
  ErrorText,
  LabelInput,
  FileLabel,
  Text,
  Title,
  ButtonContainer,
  FileInputContainer,
  TypeInputContainer,
} from './styles';

interface IProps {
  setModalDocuments: (value: boolean) => void;
  setDocuments: (documents: Array<TransferDocuments>) => void;
  typeDocument?: Array<string>;
}

const allowedExtensions = ['pdf'];

const MultiDocumentsModal = ({
  setDocuments,
  setModalDocuments,
  typeDocument,
}: IProps) => {
  const fileRef = createRef<HTMLInputElement>();

  const [documentFile, setDocumentFile] = useState<Array<TransferDocuments>>(
    []
  );
  const [errorMessage, setErrorMessage] = useState('');

  const handleDocumentChange = useCallback(
    (e: any, type: string) => {
      if (!e.target.files.length) return;

      const inputFile = e.target.files[0];
      const fileExtension = inputFile?.type.split('/')[1];

      if (!allowedExtensions.includes(fileExtension)) {
        setErrorMessage("O Formato do arquivo tem que ser '.pdf'");
        return;
      }

      const uploadedDoc = documentFile.find((doc) => doc.type === type);

      if (uploadedDoc) {
        const index = documentFile.findIndex((doc) => doc.type === type);
        documentFile.splice(index, 1);

        setDocumentFile([
          ...documentFile,
          {
            file: inputFile,
            name: inputFile.name,
            type,
          },
        ]);
      } else {
        setDocumentFile([
          ...documentFile,
          {
            file: inputFile,
            name: inputFile.name,
            type,
          },
        ]);
      }

      setErrorMessage('');
    },
    [documentFile]
  );

  const handleGetName = (type: string) => {
    const document = documentFile.find((doc) => doc.type === type);
    return document?.name;
  };

  const onSubmit = () => {
    setDocuments(documentFile);
    setModalDocuments(false);
  };

  return (
    <Container>
      <Content>
        <Title>Anexar documento</Title>
        <Text>Você pode anexar um arquivo .pdf de até 125MB.</Text>
        <form>
          {typeDocument?.map((type, index) => (
            <div className="inputWrapper" key={index}>
              <TypeInputContainer>
                {index === 0 && <LabelInput>Tipo</LabelInput>}
                <StyledInput
                  placeholder="Tipo de documento"
                  value={type}
                  disabled
                  sizeDisplay="100"
                  heigth="64"
                  fontSize="20"
                />
              </TypeInputContainer>

              <FileInputContainer>
                {index === 0 && <LabelInput>Documento</LabelInput>}
                <FileLabel
                  hasDocument={!documentFile.find((doc) => doc.type === type)}
                >
                  <input
                    onChange={(e) => handleDocumentChange(e, type)}
                    ref={fileRef}
                    name="file"
                    type="File"
                  />

                  <p>
                    {' '}
                    {!documentFile.find((doc) => doc.type === type)
                      ? 'Selecionar Arquivo'
                      : handleGetName(type)}
                  </p>
                </FileLabel>
              </FileInputContainer>
            </div>
          ))}
          <ErrorText>{errorMessage}</ErrorText>
        </form>

        <ButtonContainer hasDocument={!(documentFile.length === typeDocument?.length)}>
          <StyledButton
            type="button"
            className="modal-button"
            iconPosition="left"
            icon={<ArrowLeft size={24} weight="bold" />}
            text="Voltar"
            onClick={() => setModalDocuments(false)}
          />
          <StyledButton
            type="button"
            iconPosition="left"
            icon={<Paperclip size={26} />}
            className="modal-button submit"
            text="Anexar documento"
            onClick={() => (documentFile.length ? onSubmit() : '')}
          />
        </ButtonContainer>
      </Content>
    </Container>
  );
};


export { MultiDocumentsModal };
