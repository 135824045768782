import styled from 'styled-components';

interface IProps {
  exeption?: boolean;
  noHaveMore?: boolean;
  haveList?: boolean;
}

export const Container = styled.div`
  width: 100%;

  .vertical-timeline {
    width: 100%;
    max-width: none;
    padding: 5px 0;
  }

  .vertical-timeline::before {
    display: none;
    top: 8px;
    left: 126px;
    width: 2px;

    background: ${({ theme }) => theme.colors.green.success};
  }
`;

export const TextInformation = styled.div`
  display: flex;
  gap: 4px;

  @media only screen and (max-width: 480px) {
    max-width: 150px;
  }
`;

export const ListDocumentsSent = styled.ul`
  display: flex;
  margin: 10px;
  flex-direction: column;
  gap: 10px;

  li {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;

    list-style: none;
    height: fit-content;

    a {
      color: #4510a3;
      font-weight: bold;
    }

    :before {
      content: '•';
      color: #c2c2c2;
    }
  }
`;

export const Content = styled.div<IProps>`
  display: flex;
  gap: 29px;

  .vertical-timeline-element {
    height: 100%;
  }

  .vertical-timeline-element-content {
    width: 100%;
    background-color: transparent;
    box-shadow: none;

    padding: 0.5em;

    h3 {
      color: ${({ theme, exeption }) =>
        exeption ? theme.colors.red : theme.colors.green.success};
      margin-bottom: 8px;
    }

    .term {
      font-weight: 700;
      color: ${({ theme }) => theme.colors.blue.primary};
    }

    @media only screen and (max-width: 480px) {
      h3 {
        max-width: 150px;
      }
    }
  }

  .vertical-timeline-element:after {
    position: absolute;
    top: 0;
    left: 18px;
    width: 2px;
    height: 100%;
    background-color: ${({ theme, noHaveMore }) =>
      noHaveMore ? theme.colors.neutral[400] : theme.colors.green.success};
  }

  .vertical-timeline-element {
    margin: 0 0 0.2em 0;
    min-height: 75px;

    span {
      background: ${({ theme }) => theme.colors.white} !important;
    }
  }

  .vertical-timeline-element-date {
    display: none;
  }

  ul {
    display: ${({ haveList }) => (haveList ? 'flex' : 'none')};
  }
`;

export const DateContainer = styled.div`
  width: 5%;
  min-width: 85px;

  p {
    text-align: end;

    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.neutral[100]};
  }
`;

export const StepContainer = styled.div<IProps>`
  width: 100%;
  max-width: 75%;

  .vertical-timeline-element-icon {
    box-shadow: none;
    border: 3.5px solid
      ${({ theme, exeption }) =>
        exeption ? theme.colors.red : theme.colors.green.success};

    z-index: 1;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 16px;

  margin-top: 24px;

  button {
    font-size: 20px;
    font-weight: 500;
    padding: 12px 16px;

    background-color: ${({ theme }) => theme.colors.blue.secondary};
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;

    button {
      max-width: 150px;
      font-size: 12px;
      text-align: left;
    }
  }
`;

export const PercentText = styled.p<IProps>`
  position: relative;
  top: ${({ exeption }) => (exeption ? '-5px' : '10px')};
  left: 5px;

  font-size: 18px;
  font-weight: 700;

  color: ${({ theme }) => theme.colors.yellow};
`;

export const DocumentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;

  margin-top: 20px;
`;

export const DocumentContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  border-radius: 100px;

  background-color: ${({ theme }) => theme.colors.blue.secondary};
`;
