// eslint-disable-next-line import/no-extraneous-dependencies
import ClipLoader from "react-spinners/ClipLoader";

import theme from 'src/assets/styles/themes/default';

interface IProps {
  iconWhiteBackground: boolean;
  loading: boolean;
}

export const SpinnerElement = ({ iconWhiteBackground, loading }: IProps) => (
  <ClipLoader
    color={iconWhiteBackground ? theme.colors.blue.primary : theme.colors.white}
    loading={loading}
    size={16}
    aria-label="Loading Spinner"
    data-testid="loader"
  />
);
