import styled from 'styled-components';

interface IProps {
  policyStatus: string;
}

export const Container = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  justify-content: space-between;

  padding: 16px 24px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  box-shadow: ${({ theme }) => theme.shadow.default};
  background-color: ${({ theme }) => theme.colors.white};

  div {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  h4 {
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral[300]};
  }

  p {
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.neutral[100]};
  }

  .card-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: 15vw 15vw 15vw;
    grid-column-gap: 10px;
    grid-row-gap: 1em;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
    gap: 16px;

    .card-grid {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;

  p {
    display: flex;
    justify-content: center;
    align-items: center;

    min-width: 148px;
    height: 48px;
    border-radius: 4px;
    font-weight: 700;
  }

  .in-progress {
    color: ${({ theme }) => theme.colors.yellow};
    background-color: ${({ theme }) => theme.colors.opacity.yellow};
  }

  .in-force {
    color: ${({ theme }) => theme.colors.green.success};
    background-color: ${({ theme }) => theme.colors.opacity.green};
  }

  .defeated {
    color: ${({ theme }) => theme.colors.red};
    background-color: ${({ theme }) => theme.colors.opacity.red};
  }
`;
