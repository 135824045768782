/* eslint-disable no-underscore-dangle */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

export class HttpService {
  protected readonly instance: AxiosInstance;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this._initializeResponseInterceptor();
  }

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalConfig = error.config;
        if (error.response) {
          if (error.response.status === 401 && !originalConfig.retry) {
            try {
              originalConfig.retry = true;
              this.instance.defaults.headers.common.authorization = `Bearer ${localStorage.getItem(
                '@Granto:refresh'
              )}`;
              const refreshResponse = await this.instance.get(
                '/api/Login/refresh'
              );

              const { accessToken, refreshToken } = refreshResponse.data;
              localStorage.setItem('@Granto:token', accessToken);
              localStorage.setItem('@Granto:refresh', refreshToken);

              originalConfig.headers.authorization = `Bearer ${localStorage.getItem(
                '@Granto:token'
              )}`;

              this.instance.defaults.headers.common.authorization = `Bearer ${localStorage.getItem(
                '@Granto:token'
              )}`;

              const response = await this.instance(originalConfig);
              return response;
            } catch (err: any) {
              if (err.response.status) {
                console.log(err.response.status);
              }
            }
          }
        }

        return Promise.reject(error);
      }
    );
  };

  async makeHttpRequest(config: AxiosRequestConfig) {
    const httpResponse = await this.instance.request(config);
    return httpResponse;
  }
}
