import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useCookies } from 'react-cookie';
import { connect, useSelector } from 'react-redux';

import { setUser } from 'src/redux/slices/user';
import store, { RootState } from 'src/redux/store';
import { FormatUser } from 'src/utils';

import { grantoApi } from '../services';

interface User {
  id: number;
  email: string;
  profile: string;
  name: string;
  nameReduce: string;
  avatar: string;
}

interface AuthData {
  Email: string;
  Password: string;
}

interface changePasswordRequest {
  Token: string;
  Password: string;
}

interface AuthContextData {
  userLogged: User;
  signIn(credentials: AuthData): Promise<any>;
  forgotPassword(email: string): Promise<any>;
  changePassword(body: changePasswordRequest): Promise<any>;
  signOut(): void;
  handleChangeAvatar(value: string): void;
}

interface AppProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider = ({ children }: AppProps) => {
  const [userToken, setUserToken] = useState('');
  const [cookies, setCookie, removeCookie] = useCookies(['@Granto:user']);
  const [userLogged, setUserLogged] = useState<User>({} as User);
  const { user } = useSelector((state: RootState) => state.user);

  const signIn = useCallback(
    async ({ Email, Password }: AuthData) => {
      try {
        const response = await grantoApi.makeHttpRequest({
          method: 'POST',
          url: '/login',
          data: {
            Email,
            Password,
          },
        });
        const { token, refreshToken, login } = response.data;

        localStorage.setItem('@Granto:token', token);
        localStorage.setItem('@Granto:refresh', refreshToken);

        setUserToken(token);

        const res = await grantoApi.makeHttpRequest({
          method: 'GET',
          url: '/user/me',
        });

        store.dispatch(setUser(FormatUser(res.data)));

        setCookie('@Granto:user', { user: login }, { path: '/' });

        return {
          status: 'success',
          message: 'success',
          data: response.data,
        };
      } catch (error: any) {
        return {
          status: 'error',
          code: error.response.status,
          message: error.response.data.message,
        };
      }
    },
    [userToken, userLogged]
  );

  const forgotPassword = useCallback(async (userEmail: string) => {
    try {
      const response = await grantoApi.makeHttpRequest({
        method: 'POST',
        url: '/login/recover-password',
        data: {
          Email: userEmail,
        },
      });

      return {
        status: 'success',
        message: 'success',
        data: response.data,
      };
    } catch (error: any) {
      return {
        status: 'error',
        code: error.response.status,
        message: error.response.data,
      };
    }
  }, []);

  const changePassword = useCallback(async (body: changePasswordRequest) => {
    try {
      const response = await grantoApi.makeHttpRequest({
        method: 'POST',
        url: '/tempory-request',
        data: body,
      });

      return {
        status: 'success',
        message: 'success',
        data: response.data,
      };
    } catch (error: any) {
      return {
        status: 'error',
        code: error.response.status,
        message: error.response.data.message,
      };
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@Granto:token');
    localStorage.removeItem('@Granto:refresh');

    removeCookie('@Granto:user', { path: '/' });
    setUserLogged({} as User);
  }, []);

  const handleChangeAvatar = useCallback((value: string) => {
    setUserLogged({ ...userLogged, avatar: value });
  }, []);

  const values = useMemo(
    () => ({
      userLogged,
      signIn,
      signOut,
      forgotPassword,
      changePassword,
      handleChangeAvatar,
    }),
    [
      userLogged,
      signIn,
      signOut,
      forgotPassword,
      changePassword,
      handleChangeAvatar,
    ]
  );

  useEffect(() => {
    const tokenStorage = localStorage.getItem('@Granto:token');
    setUserLogged(
      (cookies['@Granto:user'] && cookies['@Granto:user'].user) || ({} as User)
    );

    if (tokenStorage && !userLogged.id) {
      setUserToken(tokenStorage);

      grantoApi.setToken(tokenStorage);
    }
  }, [user, setUserLogged, setUserToken, cookies]);

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

const mapStateToProps = (state: any) => ({ user: state.user });

export default connect(mapStateToProps)(AuthProvider);
