/* eslint-disable import-helpers/order-imports */
/* eslint-disable @typescript-eslint/no-shadow */

import styled, { css } from 'styled-components';
import theme from 'src/assets/styles/themes/default';

interface IPropsInput {
  error: boolean;
  fontSize?: string;
}
interface IPropsContainer {
  sizeDisplay: '100' | '75' | '66' | '50' | '33' | '25';
  heigth?: string;
  disabled?: boolean;
  error: boolean;
}

interface IPropsLabel {
  disabled?: boolean;
  labelSize?: string;
}

export const Container = styled.div<IPropsContainer>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  border: 1.5px solid
    ${(props) => (props.error ? theme.colors.red : theme.colors.border.primary)};
  height: ${(props) => (props.heigth ? `${props.heigth}px` : '48px')};
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.white};

  ${(props) =>
    props.sizeDisplay === '100'
      ? css`
          width: 100%;
        `
      : props.sizeDisplay === '75'
      ? css`
          width: calc(75% - 8px);
        `
      : props.sizeDisplay === '66'
      ? css`
          width: calc(66% - 8px);
        `
      : props.sizeDisplay === '50'
      ? css`
          width: calc(50% - 8px);
        `
      : props.sizeDisplay === '33'
      ? css`
          width: calc(33% - 8px);
        `
      : css`
          width: calc(25%- 11px);
        `}
`;

export const Label = styled.label<IPropsLabel>`
  position: absolute;
  top: -33px;
  left: 0;
  padding: 0 4px;

  font-size: ${(props) => (props.labelSize ? `${props.labelSize}px` : '20px')};
  font-weight: 500;
  line-height: 25px;
  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.neutral[400] : theme.colors.neutral[200]};
`;

export const Input = styled.input<IPropsInput>`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : '16px')};
  line-height: 24px;
  font-weight: 500;
  outline: none;
  border: 0;
  width: 100%;
  color: ${(props) =>
    props.error ? theme.colors.red : theme.colors.neutral[100]};
  background-color: ${({ theme }) => theme.colors.white};

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral[400]};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.neutral[400]};
  }
`;

export const Error = styled.p`
  position: absolute;
  margin: 0;
  line-height: 100%;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.red};
  bottom: -20px;
  right: 0;
`;

export const Icon = styled.div`
  height: 20px;
  position: absolute;
  right: 18px;
  display: flex;
  align-items: center;

  font-size: 22px;
  color: ${({ theme }) => theme.colors.neutral[400]};
  background-color: transparent;

  z-index: 1;
`;
