import { ReactElement } from 'react';

import { FileText } from 'phosphor-react';

interface ISubmenu {
  path: string;
  title: string;
}
export interface ISibebar {
  id: number;
  icon: ReactElement;
  title: string;
  path: string;
  submenu?: ISubmenu[];
}

export default [
  {
    id: 2,
    icon: <FileText />,
    title: 'Minhas Apólices',
    path: '/admin/my-policies',
  },
];
