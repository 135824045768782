import moment from 'moment';

export const FormatDateTolist = (value: string) =>
  moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY');

export const FormatDateToFilter = (value: string) =>
  moment(value, 'DD/MM/YYYY').format('YYYY-MM-DDTHH:mm:ss');

export const FormatDateToStepTimeline = (value: string) =>
  moment(value, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm');
