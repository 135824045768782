import { Policies } from 'src/interfaces';

import { PolicyStatus } from './enum';
import { FormatDateTolist } from './FormatDate';
import { CurrencyNormalizer } from './normalizers';

export const FormatPolicyToCard = (data: Policies) => {
  let policy;

  if (data.status === PolicyStatus.INPROGRESS) {
    policy = [
      {
        head: 'Contrato',
        value: data.contractNumber,
      },
      {
        head: 'Contratada',
        value: data.contractor,
      },
      {
        head: 'Importância Segurada',
        value: data.insuredAmount
          ? CurrencyNormalizer(Number(data.insuredAmount).toFixed(2))
          : '-',
      },
    ];
  } else {
    policy = [
      {
        head: 'Contratada',
        value: data.contractor,
      },
      {
        head: 'Valor do Seguro',
        value: data.insuranceValue
          ? CurrencyNormalizer(Number(data.insuranceValue).toFixed(2))
          : '-',
      },
      {
        head: 'Data de Emissão',
        value: data.issuanceDate ? FormatDateTolist(data.issuanceDate) : '-',
      },
      {
        head: 'Companhia',
        value: data.company,
      },
      {
        head: 'Importância Segurada',
        value: data.insuredAmount
          ? CurrencyNormalizer(Number(data.insuredAmount).toFixed(2))
          : '-',
      },
      {
        head: 'Término da Vigência',
        value: data.endTerm ? FormatDateTolist(data.endTerm) : '-',
      },
    ];
  }

  return policy;
};
