export interface Policies {
  id: number;
  admCertificateDoc: string;
  contractNumber: string;
  contractor: string;
  company: string;
  policyName: string;
  policyNumber: string;
  policyDoc: string;
  regularityCertificateDoc: string;
  InsuredCorporateName: string;
  insuredAmount: number;
  insuranceValue: number;
  insuranceAmount: number;
  issuanceDate: string;
  validity: string;
  initialTerm: string;
  endTerm: string;
  status: string;
}

export interface ICardPolicy {
  head: string;
  value: string | number;
}

export interface IGetApoliciesResponse {
  total: number;
  totalPages: number;
  data: Array<Policies>;
}

export interface Map {
  [key: string]: string | undefined;
}

export const classname: Map = {
  'Em andamento': 'in-progress',
  'Em vigor': 'in-force',
  Vencida: 'defeated',
};

export const policyPageTitle: Map = {
  'Em andamento': 'Solicitação em andamento',
  'Em vigor': 'Apólice emitida',
  Vencida: 'Apólice vencida',
};
