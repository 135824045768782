import { TbLogout } from 'react-icons/tb';
import { Link, useNavigate } from 'react-router-dom';

import theme from 'src/assets/styles/themes/default';
import { useAuth } from 'src/hooks/auth';

import SidebarData, { ISibebar } from '../../utils/SidebarData';
import { AsideButton } from './components/AsideButton';
import { Container, Logout } from './styles';

export const AsideMenu = () => {
  const { userLogged, signOut } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate('/');
  };

  return (
    <Container>
      <div className="logoContainer">
        <Link to="/admin/my-policies">
          <div className="miniLogo" />
          <div className="hoverLogo" />
        </Link>
      </div>

      <div>
        <ul>
          {SidebarData.map((data: ISibebar) =>
            data.path ? (
              <Link to={data.path} key={data.id.toString()}>
                <AsideButton
                  title={data.title}
                  icon={data.icon}
                  path={data.path}
                />
              </Link>
            ) : (
              <AsideButton
                key={data.id.toString()}
                title={data.title}
                icon={data.icon}
                path={data.path}
              />
            )
          )}
        </ul>
      </div>
      <Logout onClick={handleLogout}>
        <AsideButton
          isLogout
          title="Sair"
          icon={<TbLogout color={theme.colors.red} />}
          path=""
        />
      </Logout>
    </Container>
  );
};
