/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unescaped-entities */
import { SetStateAction, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { useLocation } from 'react-router';

import theme from 'src/assets/styles/themes/default';
import { StatusModal, StyledButton, StyledInput } from 'src/components';
import { useAuth } from 'src/hooks/auth';
import { ErrorMessages } from 'src/utils';
import { TypeStatus } from 'src/utils/enum';

import { ButtonsContainer, FormContainer, InputsContainer } from '../styles';
import * as fO from '../utils/formOptions';
import { FormState } from '../utils/TypeToForm';

interface IProps {
  setFormState: (value: SetStateAction<FormState>) => void;
  minHeighToForm: string;
  setModal: (value: boolean) => void;
  setModalMessage: (value: string) => void;
  setModalType: (value: SetStateAction<TypeStatus>) => void;
}

export const PasswordChange = ({
  setFormState,
  minHeighToForm,
  setModal,
  setModalMessage,
  setModalType,
}: IProps) => {
  const { changePassword } = useAuth();
  const { search } = useLocation();

  const splitToken = search.replace('?t=', '');

  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  const [localModal, setLocalModal] = useState(false);
  const [localModalMessage, setLocalModalMessage] = useState('');
  const [localModalType, setLocalModalType] = useState<TypeStatus>(
    TypeStatus.SUCCESS
  );
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onSubmit = useCallback(async (data: any) => {
    try {
      const body = {
        Token: splitToken,
        Password: data.new_password,
      };

      const result = await changePassword(body);

      if (result.status === TypeStatus.SUCCESS) {
        setModal(true);
        setModalType(TypeStatus.SUCCESS);
        setModalMessage('Senha redefinida com sucesso');
        setFormState(FormState.SIGNIN);
      } else {
        setLocalModal(true);
        setLocalModalType(TypeStatus.ERROR);
        setLocalModalMessage(ErrorMessages(result.code));
      }
    } catch (error: any) {
      setLoading(false);
      setLocalModal(true);
      setLocalModalType(TypeStatus.ERROR);
      setLocalModalMessage(ErrorMessages(error.response.status));
    }
  }, []);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    getValues,
  } = useForm({
    mode: 'onBlur',
  });

  const canDisableButton = () => !isValid;

  const newPassword = watch('new_password');

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <>
      {localModal && (
        <StatusModal
          successTitle="Sucesso"
          errorTitle="Erro"
          setModal={setModal}
          text={localModalMessage}
          type={localModalType}
          navigateTo="/"
        />
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormContainer minHeight={minHeighToForm}>
          <h1>Nova senha</h1>
          <span style={{ maxWidth: '95%' }}>
            Sua senha deve ter pelo menos seis caracteres. Para torná-la mais
            forte, use letras maiúsculas e minúsculas, números e símbolos como !
            " ? $ % ^ &.
          </span>
          <InputsContainer>
            <StyledInput
              type={visible ? 'text' : 'password'}
              icon={
                visible ? (
                  <FiEye
                    onClick={() => {
                      setVisible(!visible);
                    }}
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.neutral[400]}
                  />
                ) : (
                  <FiEyeOff
                    onClick={() => {
                      setVisible(!visible);
                    }}
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.neutral[400]}
                  />
                )
              }
              label="Senha"
              placeholder="Digite sua senha"
              sizeDisplay="100"
              heigth={windowWidth <= 1366 ? '56' : '64'}
              fontSize={windowWidth <= 1366 ? '18' : '20'}
              labelSize={windowWidth <= 1366 ? '18' : '20'}
              register={register}
              registerProps={{
                name: 'new_password',
                options: fO.passwordOptions,
              }}
              error={!!errors.new_password}
              errorMessage={(errors.new_password?.message as string) || ''}
            />
            <StyledInput
              type={visible ? 'text' : 'password'}
              icon={
                visible ? (
                  <FiEye
                    onClick={() => {
                      setVisible(!visible);
                    }}
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.neutral[400]}
                  />
                ) : (
                  <FiEyeOff
                    onClick={() => {
                      setVisible(!visible);
                    }}
                    style={{ cursor: 'pointer' }}
                    color={theme.colors.neutral[400]}
                  />
                )
              }
              label="Digite novamente a senha"
              placeholder="Digite novamente sua senha"
              sizeDisplay="100"
              heigth={windowWidth <= 1366 ? '56' : '64'}
              fontSize={windowWidth <= 1366 ? '18' : '20'}
              labelSize={windowWidth <= 1366 ? '18' : '20'}
              register={register}
              registerProps={{
                name: 'confirm_password',
                options: fO.passwordOptions,
              }}
              error={!!errors.confirm_password}
              errorMessage={(errors.confirm_password?.message as string) || ''}
              {...register('confirm_password', {
                validate: (value) =>
                  value === newPassword ||
                  'As senhas digitadas não correspondem!',
              })}
            />
          </InputsContainer>
          <ButtonsContainer>
            <StyledButton
              className="btn-submit change"
              text="SALVAR NOVA SENHA"
              color="blue"
              type="submit"
              disabled={canDisableButton()}
              spinner={loading}
            />
          </ButtonsContainer>
        </FormContainer>
      </form>
    </>
  );
};
