import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
  }

  body {
      background-color: ${({ theme }) => theme.colors.background.primary};
    }

    button {
      cursor: pointer;
    }
`;
