export const FormatedToSelectArray = (value: any[]): any => {
  const result = value.map((data, index) => {
    const formated = {
      value: data.codeEnum || index,
      label: data.status || data,
    };
    return formated;
  });
  return result;
};
