/* eslint-disable import/no-extraneous-dependencies */
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';

import { CalendarBlank } from 'phosphor-react';

import 'moment/locale/pt';

import { Container, Icon } from './styles';

interface IProps {
  sizeDisplay?: '100' | '75' | '66' | '50' | '33' | '25';
  placeholder: string;
  value: Date;
  onChange?: any;
  minDate?: Date;
  maxDate?: Date;
  locale?: string;
}

export const StyledDateInput: React.FC<IProps> = ({
  sizeDisplay = '100',
  placeholder,
  value,
  locale = 'pt-br',
  ...props
}) => {
  const inputProps = {
    placeholder,
  };

  return (
    <Container dateValue={value} sizeDisplay={sizeDisplay}>
      <Datetime
        {...props}
        onChange={props.onChange}
        inputProps={inputProps}
        dateFormat="DD/MM/YYYY"
        timeFormat={false}
        value={value}
        closeOnSelect
        locale="pt"
      />
      <Icon>
        <CalendarBlank />
      </Icon>
    </Container>
  );
};
