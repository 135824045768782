import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const InformationContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  padding: 130px 40px 40px;
  margin-top: 80px;

  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[500]};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const InputsContainer = styled.div`
  padding: 30px 0;
  display: flex;
  gap: 16px;

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const AvatarContent = styled.div`
  width: 120px;
  position: absolute;
  top: -75px;
  left: 46%;
  right: 0;

  @media only screen and (max-width: 480px) {
    left: 38%;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  margin-top: 10px;

  .buttonClose {
    background: ${({ theme }) => theme.colors.gradient.primary};
    justify-content: center;
    margin-top: 5px;

    font-size: 20px;
  }
`;
