export const ErrorMessages = (code: number, text?: string): string => {
  try {
    const errors: {
      [key: number]: string;
    } = {
      404: 'não encontrado',
      400: 'Solicitação inválida, tente novamente!',
    };

    return errors[code];
  } catch (error) {
    return 'Erro não identificado';
  }
};
