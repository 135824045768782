/* eslint-disable react/jsx-props-no-spreading */
import {
  UseFormRegister,
  FieldValues,
  RegisterOptions,
  FieldPath,
} from 'react-hook-form';

import { Container, Error, Label, Input, Icon } from './styles';

interface IRegisterProps<
  TFieldName extends FieldPath<FieldValues> = FieldPath<FieldValues>
> {
  name: TFieldName;
  options?: RegisterOptions<FieldValues, TFieldName>;
}

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  sizeDisplay?: '100' | '75' | '66' | '50' | '33' | '25';
  error?: boolean;
  icon?: string | JSX.Element;
  errorMessage?: string;
  label?: string;
  labelSize?: string;
  heigth?: string;
  fontSize?: string;
  register?: UseFormRegister<FieldValues>;
  registerProps?: IRegisterProps;
}

const StyledInput: React.FC<IProps> = ({
  sizeDisplay = '100',
  error = false,
  errorMessage = '',
  label,
  labelSize,
  heigth,
  fontSize,
  register,
  registerProps,
  icon,
  ...rest
}) => {
  const { disabled } = rest;
  const { ...registerResult } =
    register && registerProps
      ? register(registerProps.name, registerProps.options)
      : {};

  return (
    <Container
      sizeDisplay={sizeDisplay}
      heigth={heigth}
      disabled={disabled}
      error={error}
    >
      <Label disabled={disabled} labelSize={labelSize}>
        {label}
      </Label>
      <Input {...registerResult} fontSize={fontSize} error={error} {...rest} />
      {error && errorMessage !== '' && <Error>{errorMessage}</Error>}
      {icon && <Icon>{icon}</Icon>}
    </Container>
  );
};

export { StyledInput };
