import styled from 'styled-components';

export const AsideMenuDisplay = styled.div``;

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 72px);

  .contentContainer {
    width: 100%;
  }

  .dashboardContent {
    background-color: ${({ theme }) => theme.colors.background.primary};

    background-repeat: no-repeat;
    background-position: 58px 100%, -140px 100%;

    padding: 35px 72px;
    height: 100%;

    overflow-y: auto;
  }

  @media only screen and (max-width: 480px) {
    .dashboardContent {
      padding: 16px 24px;
    }

    ${AsideMenuDisplay} {
      display: none;
    }
  }

  @media only screen and (min-width: 481px) {
    ${AsideMenuDisplay} {
      display: block;
    }
  }
`;
