import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const CardWelcome = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  width: 100%;
  padding: 40px;
  background-color: ${({ theme }) => theme.colors.white};

  box-shadow: ${({ theme }) => theme.shadow.default};
  border-radius: 10px;

  h3 {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.neutral[300]};
  }

  h1 {
    font-weight: 700;
    font-size: 36px;
    color: ${({ theme }) => theme.colors.neutral[100]};
  }

  span {
    max-width: 42vw;
    text-align: justify;
    font-weight: 500;
    font-size: 1.1vw;
    color: ${({ theme }) => theme.colors.neutral[300]};
    margin-top: 8px;

    z-index: 1;
  }
`;

export const ImageContent = styled.div`
  position: absolute;
  right: 40px;
`;
