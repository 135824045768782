/* eslint-disable import/no-extraneous-dependencies */
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';
import { SkeletonCircle } from '../SkeletonCircle';
import { SkeletonCircleAvatarContainer, SkeletonListRow } from './styles';

export const SkeletonTemplateMyInfo = () => (
  <SkeletonTheme>
    <SkeletonCircleAvatarContainer>
      <SkeletonCircle />
    </SkeletonCircleAvatarContainer>

    {[...Array(2)].map((item: number) => (
      <SkeletonListRow key={item}>
        <div className="skeleton-column">
          <Skeleton containerClassName="skeleton-list" inline height={25} />
          <Skeleton containerClassName="skeleton-list" inline height={64} />
        </div>
        <div className="skeleton-column">
          <Skeleton containerClassName="skeleton-list" inline height={25} />
          <Skeleton containerClassName="skeleton-list" inline height={64} />
        </div>
      </SkeletonListRow>
    ))}
  </SkeletonTheme>
);
