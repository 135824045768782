/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Policies } from 'src/interfaces';

type PolicyState = {
  policy: Policies;
};

export const policySlice = createSlice({
  name: 'policy',
  initialState: { policy: {} } as PolicyState,
  reducers: {
    setPolicy: (state, action) => {
      state.policy = action.payload;
    },
  },
});

export const { setPolicy } = policySlice.actions;

export default policySlice.reducer;
